import React from 'react';
import { FieldBox, Label, TextArea, Error } from './common';
import { ITextField } from './Form.interfaces';

export default function TextField(props: ITextField) {
  const {
    label,
    name,
    placeholder,
    handleChange,
    onBlur,
    value,
    disabled,
    error,
    width,
    full,
  } = props;
  return (
    <FieldBox full={full} width={width}>
      {label && <Label name={name} label={label} width={width} />}
      <TextArea
        {...props}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        error={!!error}
        dirty={!!value}
      />
      {error && <Error>{error}</Error>}
    </FieldBox>
  );
}
