import { combineReducers } from '@reduxjs/toolkit';
import devices from '../reducers/devicesReducer';
import loginInfo from '../reducers/signInReducer';
import user from '../reducers/user';
import learners from '../reducers/learnersReducer';
import learnersAddition from '../reducers/learnersAdditionReducer';
import tutorsAddition from '../reducers/tutorsAdditionReducer';
import tutors from '../reducers/tutorsReducer';
import sessionScheduled from '../reducers/sessionsReducer';

const rootReducer = combineReducers({
  user,
  loginInfo,
  devices,
  learners,
  tutorsAddition,
  learnersAddition,
  sessionScheduled,
  tutors,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
