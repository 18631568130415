/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

import IState from 'reducers';

import { Modal } from 'components';
import { FieldsBox, Field, Checkbox } from 'components/Form';
import { Subtitle } from 'components/Common';

import { getLearners, clearSessions, setActualLearner } from './home.action';

import LearnersAdditionForm from './LearnersAddition';

import './paginatorStyles.css';

import {
  LearnersAdditionContainer,
  AdditionalUser,
  Background,
  SearchGroup,
  ContainerCheckboxs,
  Paginator,
  TableGroup,
  AddSession,
  TableHeaderItem,
} from './styles';

interface ISearchValues {
  filter: {
    searchInput: string;
    radioFilter: string;
  };
  sort: {
    order: string;
    accessor: string;
    sortableName?: string;
  };
  isActive: string;
  page: number;
}

interface IPaginatorValues {
  offset: number;
  data?: [];
  perPage?: number;
  currentPage: number;
}

export default function Home() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { page, learnersData } = useSelector((state: IState) => state.learners);
  const { isMobile } = useSelector((state: IState) => state.devices);

  const [showLearnersModal, setShowLearnersModal] = useState(false);
  const initialSearchValues = {
    filter: {
      searchInput: '',
      radioFilter: 'firstName',
    },
    isActive: 'true',
    sort: {
      order: 'desc',
      accessor: 'creationDate',
      sortableName: 'creationDate',
    },
    page: 0,
  };
  const [searchValues, setSearch] =
    useState<ISearchValues>(initialSearchValues);

  const [paginator, setPaginator] = useState<IPaginatorValues>({
    offset: 0,
    data: [],
    perPage: 5,
    currentPage: 0,
  });

  useEffect(() => {
    dispatch(getLearners(searchValues));
    dispatch(clearSessions());
  }, [dispatch, searchValues]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
        sortableName: 'creationDate',
        sortable: true,
      },
      {
        Header: "Parent's First Name",
        accessor: 'userFirstName',
        sortableName: 'firstName',
        sortable: true,
      },
      {
        Header: "Parent's Last Name",
        accessor: 'userLastName',
        sortableName: 'lastName',
        sortable: true,
      },
      {
        Header: "User's email",
        accessor: 'userEmail',
      },
      {
        Header: 'Alias',
        accessor: 'alias',
        sortableName: 'alias',
        sortable: true,
      },
      {
        Header: 'Grade',
        accessor: 'grade',
      },

      {
        Header: 'Minutes unscheduled',
        accessor: 'totalUnscheduled',
      },
      {
        Header: 'Minutes purchased',
        accessor: 'totalPurchased',
      },
      {
        Header: 'Minutes used',
        accessor: 'totalUsed',
      },
      {
        Header: 'Minutes left',
        accessor: 'totalLeft',
      },
      {
        Header: 'Profile Completed',
        accessor: 'profileCompleted',
      },
    ],
    []
  );

  const handleCloseModal = () => {
    setShowLearnersModal(false);
  };

  const handleGoToLearner = (data) => {
    dispatch(setActualLearner(data));
    history.push('/learner');
  };

  const parseTableData = (item, accessor, row) => {
    if (typeof item === 'boolean')
      return <AddSession>{item ? 'Yes' : 'No'}</AddSession>;

    if (accessor === 'dateCreated')
      return moment.utc(item).local().format('MM-DD-YY HH:mm');

    if (accessor === 'totalUnscheduled') {
      return row.totalUnscheduled > 0 ? (
        <AddSession>{row.totalUnscheduled}</AddSession>
      ) : (
        <AddSession>0</AddSession>
      );
    }
    if (accessor === 'alias') {
      return (
        <AddSession>
          <button
            type="button"
            style={{
              border: 'none',
              backgroundColor: 'transparent',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => handleGoToLearner(row)}
          >
            {item}
          </button>
        </AddSession>
      );
    }

    if (['totalPurchased', 'totalUsed', 'totalLeft'].includes(accessor)) {
      return <AddSession>{item}</AddSession>;
    }
    return item;
  };

  useEffect(() => {
    if (searchValues.filter.searchInput.length > 2) {
      dispatch(getLearners(searchValues));
    }
  }, [searchValues, dispatch]);

  const handleClear = () => {
    dispatch(getLearners());
    setSearch(initialSearchValues);
  };

  const handleChange = (event) => {
    event.target.type === 'text' && event.target.value === '' && handleClear();

    setSearch((prevState: ISearchValues) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      page: 0,
    }));
  };

  const handleChangeActiveUser = (event) => {
    const newSearch = {
      ...searchValues,
      isActive: event.target.value,
    };
    setSearch(newSearch);
    setSearch((prevState: ISearchValues) => ({
      ...prevState,
      isActive: event.target.value,
    }));
    dispatch(getLearners(newSearch));
  };

  const handleSort = (column) => {
    const newOrder = {
      ...searchValues,
      sort: {
        order:
          searchValues.sort.order === '' || searchValues.sort.order === 'desc'
            ? 'asc'
            : 'desc',
        accessor: column.sortableName,
      },
    };
    if (column.sortable) {
      setSearch(newOrder);
      dispatch(getLearners(newOrder));
    }
  };

  const handleSelectPage = (number) => {
    const newSearch = {
      ...searchValues,
      page: number,
    };
    setSearch(newSearch);
    dispatch(getLearners(newSearch));
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = paginator.perPage ? selectedPage * paginator.perPage : 1;

    setPaginator({
      currentPage: selectedPage,
      offset,
    });
    handleSelectPage(selectedPage);
  };

  const renderPaginator = () =>
    page.totalPages > 1 ? (
      <ReactPaginate
        previousLabel="<"
        previousClassName="test-prev"
        nextLabel=">"
        nextClassName="test-next"
        breakLabel="..."
        breakClassName="test-break"
        pageCount={page.totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
      />
    ) : null;

  return (
    <Background>
      <FieldsBox>
        <SearchGroup>
          <Field
            name="searchInput"
            type="text"
            handleChange={handleChange}
            value={searchValues.filter.searchInput}
            placeholder="Search"
          />
          <ContainerCheckboxs>
            <Checkbox
              type="radio"
              name="radioFilter"
              value="firstName"
              checked={searchValues.filter.radioFilter === 'firstName'}
              handleChange={handleChange}
              label="First Name"
            />
            <Checkbox
              type="radio"
              name="radioFilter"
              value="lastName"
              checked={searchValues.filter.radioFilter === 'lastName'}
              handleChange={handleChange}
              label="Last Name"
            />
            <Checkbox
              type="radio"
              name="radioFilter"
              value="email"
              checked={searchValues.filter.radioFilter === 'email'}
              handleChange={handleChange}
              label="Email"
            />
            <Checkbox
              type="radio"
              name="radioFilter"
              value="alias"
              checked={searchValues.filter.radioFilter === 'alias'}
              handleChange={handleChange}
              label="Alias"
            />
            <a href="#" onClick={handleClear}>
              clear
            </a>
          </ContainerCheckboxs>
        </SearchGroup>
        <AdditionalUser onClick={() => setShowLearnersModal(true)}>
          <FontAwesomeIcon icon={faPlusCircle} size="lg" />
          <span>
            <b>Add new user</b>
          </span>
        </AdditionalUser>
      </FieldsBox>
      <SearchGroup>
        <Subtitle>Active filer:</Subtitle>
        <ContainerCheckboxs>
          <Checkbox
            type="radio"
            name="isActive"
            value="false"
            checked={searchValues.isActive === 'false'}
            handleChange={handleChangeActiveUser}
            label="Inactive"
          />
          <Checkbox
            type="radio"
            name="isActive"
            value="true"
            checked={searchValues.isActive === 'true'}
            handleChange={handleChangeActiveUser}
            label="Active"
          />
          <Checkbox
            type="radio"
            name="isActive"
            value=""
            checked={searchValues.isActive === ''}
            handleChange={handleChangeActiveUser}
            label="All"
          />
        </ContainerCheckboxs>
      </SearchGroup>
      <Modal
        isOpen={showLearnersModal}
        closeModal={handleCloseModal}
        full={isMobile}
        mw={1000}
      >
        <LearnersAdditionContainer>
          <LearnersAdditionForm />
        </LearnersAdditionContainer>
      </Modal>

      {learnersData.length ? (
        <TableGroup>
          <table
            style={{
              marginTop: 30,
              width: 150 * columns.length,
              minWidth: '100%',
              borderSpacing: 0,
            }}
          >
            <thead>
              <tr>
                {columns.map((column) => (
                  <th
                    style={{
                      fontWeight: 'bold',
                      cursor: `${column.sortable && 'pointer'}`,
                      width: 200,
                    }}
                    onClick={() => handleSort(column)}
                    key={column.Header}
                  >
                    <TableHeaderItem>
                      {column.Header}
                      {searchValues.sort.accessor === column.sortableName &&
                        (searchValues.sort.order === 'desc' ? (
                          <FontAwesomeIcon icon={faAngleDown} />
                        ) : (
                          <FontAwesomeIcon icon={faAngleUp} />
                        ))}
                    </TableHeaderItem>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {learnersData.map((row, v) => (
                <tr key={row.learnerId}>
                  {columns.map((column, i) => (
                    <td
                      style={{
                        padding: '10px',
                        background: v % 2 === 0 ? '#F6F6F6' : 'white',
                      }}
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                    >
                      {parseTableData(
                        row[column.accessor],
                        column.accessor,
                        row
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </TableGroup>
      ) : (
        <p>No results</p>
      )}
      <Paginator>{renderPaginator()}</Paginator>
    </Background>
  );
}
