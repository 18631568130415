import styled from 'styled-components';
import fileIcon from '../../assets/icons/fileIcon.svg';
import theme from '../../config/theme';

export { Background } from '../../components/Common';

export const Title = styled.h1`
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding: 8px 0;
  margin: 0 0 32px;
`;

export const Subtitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 1em;
  font-size: 20px;
`;

export const LearnersSection = styled.section`
  margin-bottom: 20px;
  animation-name: show;
  animation-duration: 0.5s;
  padding: 40px;
`;

export const LearnerBox = styled.section`
  border-radius: 8px;
  border: 1px solid;
  padding: 18px;
`;

export const LearnerHeader = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;

  svg {
    cursor: pointer;
  }
`;

export const Text = styled.p`
  margin-bottom: 0.75em;
`;

export const AdditionalLearner = styled.div`
  color: ${({ theme }) => theme.colors.primary.default};
  margin: 10px 0;
  margin-bottom: 30px;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
`;

export const AdditionalUser = styled.div`
  color: ${({ theme }) => theme.colors.primary.default};
  margin-top: 20px;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
`;

export const SubmitSection = styled.section`
  display: flex;
  margin: 10px 0;
  justify-content: center;
`;

export const SubmitButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;

  input[type='submit'] {
    margin-top: 40px;
    width: 100px;
    padding: 15px;
    border-radius: 5px;
  }
`;

export const ContainerCheckboxs = styled.div`
  font-size: 13px;
  line-height: 18.2px;
  display: flex;
  margin-left: 20px;
  margin-bottom: -24px;
  span {
    min-width: 100px;
  }
  a {
    display: flex;
    align-self: center;
    margin-bottom: 24px;
    font-size: 15px;
  }
  @media only screen and (max-width: 680px) {
    margin-left: 0px;
    flex-wrap: wrap;
    div {
      width: 50%;
    }
  }
`;

export const LearnersAdditionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const UserAddedTag = styled.p`
  color: ${({ theme }) => theme.colors.primary.default};
`;

export const SearchGroup = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    width: 100%;
    margin-top: 100px;
    flex-direction: column;
    input {
      width: 100%;
    }
  }
`;

export const TableGroup = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  width: 100%;
  th {
    color: ${({ theme }) => theme.colors.white.default};
    background-color: ${({ theme }) => theme.colors.secondary.default};
    padding: 10px 0;
  }
`;

export const Paginator = styled.div`
  display: flex;
  margin: 40px 0;
  justify-content: center;
`;

export const BoxNumber = styled.div`
  position: relative;
  width: 100%;
  max-width: 49%;
  @media only screen and (max-width: 680px) {
    max-width: 100%;
  }
  &:before {
    content: '+1';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 26px;
    width: 45px;
    height: 54px;
    background-color: white;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid #dbdcdd;
  }
  > div {
    max-width: 100%;
  }
  input {
    margin-left: 45px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const CopyLinkGroup = styled.div`
  margin-top: 20px;
  display: flex;
  position: relative;
  width: 100%;
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const BoxCopy = styled.div`
  position: relative;
  background: url(${fileIcon});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  width: 45px;
  background-color: white;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #dbdcdd;
  &:hover {
    cursor: pointer;
  }
  > div {
    max-width: 100%;
  }
`;

export const AddSession = styled.div`
  text-align: center;
`;

export const AddSessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  margin-bottom: 200px;
`;

export const DateGroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 55%;
`;

export const DateWrapper = styled.div`
  width: 50%;
  position: relative;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker {
    width: 330px;
  }
  input {
    background: ${({ theme }) => theme.colors.quaternary.default};
    border-radius: 8px;
    padding: 0 15px;
    border: 1px solid ${({ theme }) => theme.colors.quaternary.default};
    height: 54px;
    line-height: 23.8px;
    transition: all 0.25s linear;
    box-sizing: border-box;
    width: 100%;

    &:active,
    &:focus {
      background: ${({ theme }) => theme.colors.white.default};
      border: 1px solid ${({ theme }) => theme.colors.main.default};
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.tertiary.default};
      border: 0;
    }
  }
`;

export const DateLabel = styled.div`
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 8px;
`;

export const SessionsFieldsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    max-width: 90%;
  }
`;

export const AddSessionMessage = styled.div<{ visible: boolean }>`
  color: ${({ theme }) => theme.colors.primary.default};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const TableHeaderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    padding-right: 5px;
    padding-left: 10px;
  }
`;

export const CopiedPopUp = styled.div`
  position: absolute;
  top: -50px;
  right: 0;
  padding: 10px;
  color: ${theme.colors.white.default};
  background-color: ${theme.colors.main.medium};
`;

export const ErrorMessageContainer = styled.p`
  color: ${theme.colors.alert.default};
  margin-top: 20px;
`;
