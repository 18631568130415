import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { startCase } from 'lodash';
import html2canvas from 'html2canvas';

import * as styles from './chart.module.scss';

interface Result {
  rank: number;
  score: number;
  class: string;
  sum: number;
  label: string;
}
interface Props {
  results: Result[];
  ageLabel: string;
  resultsRef: React.RefObject<HTMLElement>;
}
const Chart = ({ results, ageLabel, resultsRef }: Props) => {
  const [saved, setSaved] = useState(false);

  const saveToImage = () => {
    const chart = document.getElementById('report-chart');
    const wrapper = resultsRef.current;
    if (!chart || !wrapper) {
      console.error('Could not save chart to image');
      return;
    }
    const chartWidth = chart.offsetWidth;
    const chartHeight = chart.offsetHeight;
    const chartXOffset = window.scrollX + chart.getBoundingClientRect().left;
    const chartYOffset = window.scrollY + chart.getBoundingClientRect().top;
    html2canvas(chart, {
      backgroundColor: null,
      width: chartWidth * 1.1,
      height: chartHeight * 1.1,
      x: chartXOffset,
      y: chartYOffset,
      scale: 1,
    }).then((canvas) => {
      setSaved(true);
      if (!canvas || !wrapper) {
        console.error('Could not save chart to image');
        return;
      }
      wrapper.appendChild(canvas);
    });
  };

  useEffect(saveToImage, [results, ageLabel, resultsRef]);
  const percents = [0, 25, 50, 75, 100];

  const colorScale = {
    'very superior': '#C3D3DB',
    superior: '#C3D3DB',
    'high average': '#19324D',
    average: '#1E7164',
    'low average': '#EDB393',
    'below average': '#DF6926',
    'well below average': '#DF6926',
  };

  if (saved) return null;
  return (
    <div className={styles.chartWrapper} id="report-chart">
      <h1>Executive Functioning - Parent</h1>
      <h3>CEFI {ageLabel}</h3>
      <div className={styles.chartContainer}>
        <div className={styles.background}>
          {percents.map((p) => (
            <div className={styles.percentLine} key={p}>
              <span>{p}%</span>
            </div>
          ))}
        </div>

        {results.map((r) => (
          <div className={styles.row} key={r.label}>
            <span className={styles.label}>{r.label}</span>
            <div
              className={styles.bar}
              style={{
                width: `${(r.rank / 100) * 80}%`,
                backgroundColor: colorScale[r.class],
              }}
            >
              <span className={cx(styles.class, r.rank < 20 && styles.shift)}>
                {startCase(r.class)}
              </span>
              <span className={cx(styles.percent, r.rank < 20 && styles.shift)}>
                {r.rank}%
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chart;
