import {
  INTERESTS_SERVICES,
  GRADES,
  LOCATIONS,
  FORM_DATA_SUCCESS,
  FORM_DATA_FAILURE,
  RESET_USER_ADDED,
} from '../pages/Home/LearnersAddition/learnersAdditionForm.action';

const initialState = {
  interests: [],
  grades: [],
  locations: [],
  formResponse: {},
  userAdded: false,
};

export default function learnersAdditionData(state = initialState, action) {
  switch (action.type) {
    case INTERESTS_SERVICES:
      return {
        ...state,
        interests: action.payload.interests.interests,
      };
    case GRADES:
      return {
        ...state,
        grades: action.payload.grades.grades,
      };
    case LOCATIONS:
      return {
        ...state,
        locations: action.payload.locations.states,
      };
    case FORM_DATA_SUCCESS:
      return {
        ...state,
        userAdded: true,
      };
    case FORM_DATA_FAILURE:
      return {
        ...state,
        formResponse: action.payload,
      };
    case RESET_USER_ADDED:
      return {
        ...state,
        userAdded: false,
      };
    default:
      return state;
  }
}
