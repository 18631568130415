import styled from 'styled-components';

export { Background } from '../../components/Common';

export { TableGroup, Paginator } from '../Home/styles';

export const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SearchGroup = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    width: 100%;
    margin-top: 100px;
    flex-direction: column;
    input {
      width: 100%;
    }
  }
`;

export const CheckboxContainer = styled.div<{ margin?: boolean }>`
  width: 100%;
  font-size: 13px;
  line-height: 18.2px;
  display: flex;
  ${({ margin }) => margin && `margin-left: 20px`};
  span {
    min-width: 100px;
  }
  a {
    display: flex;
    align-self: center;
    margin-bottom: 24px;
    font-size: 15px;
  }
  @media only screen and (max-width: 680px) {
    margin-left: 0px;
    flex-wrap: wrap;
    div {
      width: 50%;
    }
  }
`;

export const TableHeaderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    padding-right: 5px;
    padding-left: 10px;
  }
`;

export const AddTutor = styled.div`
  color: ${({ theme }) => theme.colors.primary.default};
  margin-top: 20px;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
`;

export const TutorsAdditionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Title = styled.h1`
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding: 8px 0;
  margin: 0 0 32px;
`;

export const Subtitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 1em;
  font-size: 20px;
`;

export const TutorAddedTag = styled.p`
  color: ${({ theme }) => theme.colors.primary.default};
`;

export const SubmitSection = styled.section`
  display: flex;
  margin: 10px 0;
  justify-content: center;
`;

export const SubmitButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;

  input[type='submit'] {
    margin-top: 40px;
    width: 100px;
    padding: 15px;
    border-radius: 5px;
  }
`;

export const EditLearner = styled.div`
  text-align: center;
`;
