export interface IFormData {
  aboutMe?: string;
  calendarUrl: string;
  email: string;
  firstName: string;
  introVideoUrl?: string;
  lastName: string;
  profileImageUrl?: string;
  specialist?: boolean;
  state: number;
  titleDegree?: string;
  online: boolean;
  inhome: boolean;
}

export interface IdbItem {
  value: number;
  label: string;
}

export interface IFormValues {
  aboutMe?: string;
  calendarUrl: string;
  email: string;
  firstName: string;
  introVideoUrl?: string;
  lastName: string;
  profileImageUrl?: string;
  specialist: string;
  stateOfResidence?: { value: number; label: string };
  titleDegree?: string;
  online: string;
  inhome: string;
}

export interface IFormEdit {
  id: string;
  aboutMe?: string;
  calendarUrl: string;
  email: string;
  firstName: string;
  introVideoUrl?: string;
  lastName: string;
  profileImageUrl?: string;
  specialist: boolean;
  state: number;
  titleDegree?: string;
  online: boolean;
  inhome: boolean;
}

export const initialState = {
  locations: [],
  servicesInterestedIn: [],
  grades: [],
  lastLearnerCreated: 0,
  errors: [],
  showError: false,
  formValues: {
    aboutMe: '',
    calendarUrl: '',
    email: '',
    firstName: '',
    introVideoUrl: '',
    lastName: '',
    profileImageUrl: '',
    specialist: 'no',
    stateOfResidence: { value: 0, label: '' },
    titleDegree: '',
    online: 'no',
    inhome: 'no',
  },
};
