const csvParser = (csv) => {
  const arr: Array<Array<string>> = [];
  let quote = false;

  let row = 0;
  let col = 0;

  for (let c = 0; c < csv.length; c += 1) {
    const cc = csv[c];
    const nc = csv[c + 1];
    arr[row] = arr[row] || [];
    arr[row][col] = arr[row][col] || '';

    if (cc === '"' && quote && nc === '"') {
      arr[row][col] += cc;
      c += 1;
      continue;
    }
    if (cc === '"') {
      quote = !quote;
      continue;
    }
    if (cc === ',' && !quote) {
      col += 1;
      continue;
    }
    if (cc === '\n' && !quote) {
      row += 1;
      col = 0;
      continue;
    }

    arr[row][col] += cc;
  }

  return arr;
};

export default csvParser;
