/* eslint-disable no-underscore-dangle */
import {
  GET_LEARNERS_SUCCESS,
  SET_ACTUAL_LEARNER,
  GET_ACTIVATION_LINK,
} from 'pages/Home/home.action';

import {
  UPDATE_ACTUAL_LEARNER,
  SET_ACTUAL_LEARNER_SESSION_DETAILS,
} from 'pages/Learner/learner.action';

const initialState = {
  learnersData: [
    {
      alias: '',
      grade: '',
      parentScreenerStatus: 'PENDING',
      learnerScreenerStatus: 'PENDING',
      screenerReviewStatus: 'PENDING',
      initialPlanPayed: false,
      userFirstName: '',
      userLastName: '',
      profileCompleted: false,
      defaultUser: false,
      learnerId: '',
    },
  ],
  page: {
    number: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0,
  },
  actualLearner: {
    alias: '',
    dateCreated: '',
    defaultUser: false,
    grade: '',
    initialPlanPayed: false,
    learnerId: '',
    learnerScreenerStatus: '',
    sessionDetails: [],
    payments: [],
    parentScreenerStatus: '',
    profileCompleted: false,
    screenerReviewStatus: '',
    totalSessionsLeft: 0,
    totalSessionsPurchased: 0,
    totalSessionsUnscheduled: 0,
    totalSessionsUsed: 0,
    userId: '',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
  },
  activationLink: '',
};

export default function devices(state = initialState, action) {
  switch (action.type) {
    case GET_LEARNERS_SUCCESS:
      return {
        ...state,
        learnersData: action.learners._embedded
          ? action.learners._embedded.learners
          : [],
        page: action.learners.page,
        activationLink: '',
      };
    case SET_ACTUAL_LEARNER:
      return {
        ...state,
        actualLearner: action.learner,
      };
    case UPDATE_ACTUAL_LEARNER:
      return {
        ...state,
        actualLearner: { ...state.actualLearner, ...action.actualLearner },
      };
    case SET_ACTUAL_LEARNER_SESSION_DETAILS: {
      return {
        ...state,
        actualLearner: {
          ...state.actualLearner,
          sessionDetails: [...action.sessionDetails],
        },
      };
    }
    case GET_ACTIVATION_LINK:
      return {
        ...state,
        activationLink: action.activationLink.url,
      };
    default:
      return state;
  }
}
