import React, { useEffect } from 'react';

import ParentScreenerReport from './ParentScreenerReport';
import LiteracyScreenerReport from './LiteracyScreenerReport';

import * as styles from './reports.module.scss';

export default function Reports() {
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0));
  }, []);

  return (
    <div className={styles.wrapper}>
      <h1>Reports</h1>

      <ParentScreenerReport />
      <LiteracyScreenerReport />
    </div>
  );
}
