import axios from 'axios';

export const UPDATE_ACTUAL_LEARNER = 'UPDATE_ACTUAL_LEARNER';
export const SET_ACTUAL_LEARNER_SESSION_DETAILS =
  'SET_ACTUAL_LEARNER_SESSION_DETAILS';

const HOST_ADMIN_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/admin`;
const HOST_TUTORING = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/tutoring/api/v1/`;

const updateActualLearner = (actualLearner) => ({
  type: UPDATE_ACTUAL_LEARNER,
  actualLearner,
});

const setActualLearnerSessionDetails = (sessionDetails) => ({
  type: SET_ACTUAL_LEARNER_SESSION_DETAILS,
  sessionDetails,
});

export const updateUserProfile =
  (userOptions, userId: string) => (dispatch) => {
    const token = localStorage.getItem('token');
    axios
      .patch(`${HOST_ADMIN_PROFILE}/user/${userId}`, userOptions, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) =>
        dispatch(
          updateActualLearner({
            userFirstName: res.data.firstName,
            userLastName: res.data.lastName,
          })
        )
      )
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('update user error', err);
        // dispatch(updateUserFailure('Something went wrong'));
      });
  };

export const updateLearnerProfile =
  (learnerOptions, userId, learnerId) => (dispatch) => {
    const token = localStorage.getItem('token');

    axios
      .patch(
        `${HOST_ADMIN_PROFILE}/user/${userId}/learner/${learnerId}`,
        learnerOptions,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => dispatch(updateActualLearner(res.data)))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('update learner error', err);
        // dispatch(updateUserFailure('Something went wrong'));
      });
  };

export const getLearnerProfile = (userId, learnerId) => (dispatch) => {
  const token = localStorage.getItem('token');

  axios
    .get(`${HOST_ADMIN_PROFILE}/user/${userId}/learner/${learnerId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch(updateActualLearner(res.data));
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error('update learner error', err);
      // dispatch(updateUserFailure('Something went wrong'));
    });
};

export const getLearnerSessions = (learnerId, completed?) => (dispatch) => {
  const token = localStorage.getItem('token');
  axios
    .get(
      `${HOST_TUTORING}package/sessions?learnerId=${learnerId}&size=40&packageType=tutoring${
        completed && '&excludeCompletedSessions=true'
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      if (res.data.content?.dynamic) {
        const sessionDetails = res.data.content.dynamic.sessionDetails || [];
        dispatch(setActualLearnerSessionDetails(sessionDetails));
      }
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error('getUser Error', err);
      // dispatch(updateLearnerByIdFailure(err.message));
    });
};
