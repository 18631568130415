import { Scores } from './literacy.interfaces';

import cleanComment from './cleanComment';

const getScores = (section, questions, answers) => {
  const scores: Scores = {
    total: 0,
    correct: 0,
    incorrect: 0,
    skipped: 0,
    comments: [],
    practice: null,
  };

  for (let i = section.startIndex; i <= section.endIndex; i += 1) {
    const question = questions[i];
    if (!question) continue;
    const answer = answers[i];
    if (question.toLowerCase().includes('other')) {
      const response = answer;
      if (response && response !== '\r')
        scores.comments.push(cleanComment(response));
      continue;
    } else if (question.toLowerCase().includes('practice')) {
      scores.practice = answer;
    } else {
      scores.total += 1;
      if (answer === '1') scores.correct += 1;
      else if (answer === '0') scores.incorrect += 1;
      else scores.skipped += 1;
    }
  }

  return scores;
};

export default getScores;
