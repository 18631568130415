const erConversion = {
  45: {
    rank: 99,
    score: 133,
    class: 'very superior',
  },
  44: {
    rank: 98,
    score: 131,
    class: 'very superior',
  },
  43: {
    rank: 97,
    score: 129,
    class: 'superior',
  },
  42: {
    rank: 96,
    score: 127,
    class: 'superior',
  },
  41: {
    rank: 95,
    score: 125,
    class: 'superior',
  },
  40: {
    rank: 94,
    score: 123,
    class: 'superior',
  },
  39: {
    rank: 92,
    score: 121,
    class: 'superior',
  },
  38: {
    rank: 90,
    score: 119,
    class: 'high average',
  },
  37: {
    rank: 87,
    score: 117,
    class: 'high average',
  },
  36: {
    rank: 84,
    score: 115,
    class: 'high average',
  },
  35: {
    rank: 82,
    score: 114,
    class: 'high average',
  },
  34: {
    rank: 77,
    score: 111,
    class: 'high average',
  },
  33: {
    rank: 75,
    score: 110,
    class: 'high average',
  },
  32: {
    rank: 68,
    score: 107,
    class: 'average',
  },
  31: {
    rank: 66,
    score: 106,
    class: 'average',
  },
  30: {
    rank: 58,
    score: 103,
    class: 'average',
  },
  29: {
    rank: 53,
    score: 101,
    class: 'average',
  },
  28: {
    rank: 50,
    score: 100,
    class: 'average',
  },
  27: {
    rank: 45,
    score: 98,
    class: 'average',
  },
  26: {
    rank: 37,
    score: 95,
    class: 'average',
  },
  25: {
    rank: 34,
    score: 94,
    class: 'average',
  },
  24: {
    rank: 27,
    score: 91,
    class: 'average',
  },
  23: {
    rank: 23,
    score: 89,
    class: 'low average',
  },
  22: {
    rank: 18,
    score: 86,
    class: 'low average',
  },
  21: {
    rank: 16,
    score: 85,
    class: 'low average',
  },
  20: {
    rank: 12,
    score: 82,
    class: 'low average',
  },
  19: {
    rank: 10,
    score: 81,
    class: 'low average',
  },
  18: {
    rank: 7,
    score: 78,
    class: 'below average',
  },
  17: {
    rank: 6,
    score: 77,
    class: 'below average',
  },
  16: {
    rank: 4,
    score: 74,
    class: 'below average',
  },
  15: {
    rank: 4,
    score: 73,
    class: 'below average',
  },
  14: {
    rank: 2,
    score: 70,
    class: 'below average',
  },
  13: {
    rank: 2,
    score: 69,
    class: 'well below average',
  },
  12: {
    rank: 1,
    score: 66,
    class: 'well below average',
  },
  11: {
    rank: 1,
    score: 65,
    class: 'well below average',
  },

  10: {
    rank: 1,
    score: 62,
    class: 'well below average',
  },
  9: {
    rank: 1,
    score: 61,
    class: 'well below average',
  },
  8: {
    rank: 1,
    score: 58,
    class: 'well below average',
  },
  7: {
    rank: 1,
    score: 57,
    class: 'well below average',
  },
  6: {
    rank: 1,
    score: 54,
    class: 'well below average',
  },
  5: {
    rank: 1,
    score: 53,
    class: 'well below average',
  },
  4: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  3: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  2: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  1: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  0: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
};

export default erConversion;
