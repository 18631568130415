import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';

import { sortBy, capitalize } from 'lodash';

import { Modal, Spinner } from 'components';
import { UserIcon } from 'components/Common';

import { IsExpired } from 'helpers/timeHelper';

import { logout } from 'commonActions';

import IState from 'reducers';

import specialistLogo from 'assets/images/LF-Avatar-Blue.png';

import {
  getTutorSessionsDuration,
  saveScheduledEventDynamic,
} from './schedulerActions';

import {
  Title,
  SessionEvent,
  SchedulerModal,
  CalendlyContainer,
  EventParticipants,
  SpinnerContainer,
  LFAvatar,
  Error,
} from './styles';

interface ICalendlyPrefillProps {
  url: string;
  prefill: {
    name: string;
    email: string;
  };
  styles: {
    height: string;
  };
}

interface IPrefillRef {
  name: string;
  email: string;
}
const InlineWidgetMemo = React.memo((props: ICalendlyPrefillProps) => (
  <InlineWidget {...props} />
));
interface Props {
  learnerId: string;
  learnerAlias: string;
  userEmail: string;
  tutor: {
    ids: string;
    email: string;
    calendarUrl: string;
    firstName: string;
    lastName: string;
    titleDegree: string;
  } | null;
  closeModal: () => void;
}

export default function Scheduler({
  learnerId,
  learnerAlias,
  userEmail,
  tutor,
  closeModal,
}: Props) {
  const dispatch = useDispatch();
  const [sessionOptions, setSessionOptions] = useState([]);
  const [sessionEvent, setSessionEvent] = useState({ calendarUrl: '' });
  const [error, setError] = useState('');

  const [calendlyLoaded, setCalendlyLoaded] = useState(false);

  const history = useHistory();

  const fetchSession = async (tutor) => {
    const sessionOptions = await getTutorSessionsDuration(tutor.email);
    if (!sessionOptions)
      return setError('Could not fetch session events from backend');
    return setSessionOptions(sessionOptions);
  };

  useEffect(() => {
    if (!tutor) return;
    fetchSession(tutor);
  }, [tutor]);

  const { isMobile } = useSelector((state: IState) => state.devices);

  const selectEvent = (session) => {
    setSessionEvent(session);
  };

  const sessionScheduled = (event) => {
    const eventArr = event.data.payload.event.uri.split('/');
    const inviteeArr = event.data.payload.invitee.uri.split('/');
    const calendlyObj = {
      eventId: eventArr[eventArr.length - 1],
      inviteeId: inviteeArr[inviteeArr.length - 1],
      sessionType: 'TUTORING',
      email: tutor?.email,
      learnerId,
    };
    dispatch(saveScheduledEventDynamic(calendlyObj));
  };

  const checkToken = () => {
    const token = localStorage.getItem('token');
    if (IsExpired(token)) {
      dispatch(logout);
      localStorage.removeItem('token');
      localStorage.clear();
      history.push('/signIn');
    }
  };

  const prefillRef = useRef<IPrefillRef>({
    name: learnerAlias,
    email: userEmail,
  });
  const styleRef = useRef({
    height: '800px',
    maxHeight: '90vh',
  });

  if (!tutor) return null;

  return (
    <Modal
      isOpen
      closeModal={closeModal}
      full={isMobile}
      mw={sessionEvent.calendarUrl ? 800 : 500}
    >
      {sessionEvent.calendarUrl ? (
        <SchedulerModal>
          {!calendlyLoaded && (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}

          <EventParticipants>
            <div>
              <UserIcon />
              <b>{`${capitalize(learnerAlias)}'s`}</b>
            </div>
            new session with
            <div>
              <LFAvatar>
                <img src={specialistLogo} alt="specialist" />
              </LFAvatar>
              <span>
                <b>{`${tutor.firstName} ${tutor.lastName}`}</b>
                <p>{tutor.titleDegree}</p>
              </span>
            </div>
          </EventParticipants>
          <CalendlyContainer>
            <CalendlyEventListener
              onEventTypeViewed={() => {
                checkToken();
                setCalendlyLoaded(true);
              }}
              onDateAndTimeSelected={() => checkToken()}
              onEventScheduled={sessionScheduled}
            />
            <InlineWidgetMemo
              url={`${sessionEvent?.calendarUrl}?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=DF6A26&`}
              prefill={prefillRef.current}
              styles={styleRef.current}
            />
          </CalendlyContainer>
        </SchedulerModal>
      ) : (
        <SchedulerModal>
          <Title>Session Duration</Title>
          <p>
            Select the duration for <b>{learnerAlias}</b> session with{' '}
            <b>
              {tutor.firstName} {tutor.lastName}
            </b>
          </p>
          <br />
          <br />
          {!!error && <Error>{error}</Error>}
          {!sessionOptions?.length && <Spinner />}

          {sortBy(sessionOptions, 'duration').map(
            (event: { duration: number; calendarUrl: string }) => (
              <SessionEvent
                key={event.calendarUrl}
                onClick={() => selectEvent(event)}
              >
                {event.duration} minutes
              </SessionEvent>
            )
          )}
        </SchedulerModal>
      )}
    </Modal>
  );
}
