import styled from 'styled-components/';

export { Background } from 'components/Common';

export const RecommendedTutor = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  div {
    margin-right: 20px;
    font-size: 18px;
  }

  svg {
    cursor: pointer;
  }
`;

export const RecommendedTutorSide = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 50%; */
`;

export const SessionCheckBoxContainer = styled.div`
  display: flex;
  width: 300px;
  margin-left: 20px;
  margin-top: -5px;
  margin-bottom: 10px;
`;

export const TutorsBox = styled.section`
  border-radius: 8px;
  border: 1px solid;
  padding: 18px;
`;

export const AddRecommendedTutor = styled.div`
  color: ${({ theme }) => theme.colors.primary.default};
  margin-top: 20px;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
`;

export const RecommendedTutorModal = styled.div`
  display: flex;
  padding: 0;
  height: 400px;
  position: relative;
  flex-direction: column;
  form {
    width: 100%;
    padding-left: 5px;
  }
`;

export const RecomendedButtonsGroup = styled.div`
  width: 100%;
  display: flex;
  margin-right: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
`;

export const LearnerField = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  b {
    margin-bottom: 5px;
    margin-right: 10px;
  }
`;

export const NoEditable = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
`;

export const LearnerColumn = styled.div`
  width: 45%;
`;

export const RecomendedHeader = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  button {
    height: 30px;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
  padding: 0 15%;
`;

export const RecommendedTitle = styled.h1`
  padding: 8px 0;
`;

export const TableGroup = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  th {
    color: ${({ theme }) => theme.colors.white.default};
    background-color: ${({ theme }) => theme.colors.secondary.default};
    padding: 10px 0;
  }
`;

export const TableHeaderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RecommendedTutorAddedTag = styled.p`
  color: ${({ theme }) => theme.colors.primary.default};
`;

export const RecommendedTutorDeletedTag = styled.p`
  color: ${({ theme }) => theme.colors.primary.default};
  justify-content: center;
  display: flex;
  margin-top: 30px;
`;

export const Highlight = styled.p`
  color: ${({ theme }) => theme.colors.primary.default};
  font-size: 1.2rem;
`;
