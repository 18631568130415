const ogConversion = {
  50: {
    rank: 99,
    score: 139,
    class: 'very superior',
  },
  49: {
    rank: 99,
    score: 138,
    class: 'very superior',
  },
  48: {
    rank: 99,
    score: 136,
    class: 'very superior',
  },
  47: {
    rank: 99,
    score: 133,
    class: 'very superior',
  },
  46: {
    rank: 98,
    score: 132,
    class: 'very superior',
  },
  45: {
    rank: 98,
    score: 130,
    class: 'very superior',
  },
  44: {
    rank: 97,
    score: 128,
    class: 'superior',
  },
  43: {
    rank: 96,
    score: 126,
    class: 'superior',
  },
  42: {
    rank: 95,
    score: 124,
    class: 'superior',
  },
  41: {
    rank: 94,
    score: 123,
    class: 'superior',
  },
  40: {
    rank: 92,
    score: 121,
    class: 'superior',
  },
  39: {
    rank: 90,
    score: 119,
    class: 'high average',
  },
  38: {
    rank: 87,
    score: 117,
    class: 'high average',
  },
  37: {
    rank: 86,
    score: 116,
    class: 'high average',
  },
  36: {
    rank: 82,
    score: 114,
    class: 'high average',
  },
  35: {
    rank: 81,
    score: 113,
    class: 'high average',
  },
  34: {
    rank: 77,
    score: 111,
    class: 'high average',
  },
  33: {
    rank: 73,
    score: 109,
    class: 'average',
  },
  32: {
    rank: 66,
    score: 106,
    class: 'average',
  },
  31: {
    rank: 63,
    score: 105,
    class: 'average',
  },
  30: {
    rank: 61,
    score: 104,
    class: 'average',
  },
  29: {
    rank: 53,
    score: 101,
    class: 'average',
  },
  28: {
    rank: 50,
    score: 100,
    class: 'average',
  },
  27: {
    rank: 42,
    score: 97,
    class: 'average',
  },
  26: {
    rank: 39,
    score: 96,
    class: 'average',
  },
  25: {
    rank: 34,
    score: 94,
    class: 'average',
  },
  24: {
    rank: 32,
    score: 93,
    class: 'average',
  },
  23: {
    rank: 25,
    score: 90,
    class: 'average',
  },
  22: {
    rank: 23,
    score: 89,
    class: 'low average',
  },
  21: {
    rank: 21,
    score: 88,
    class: 'low average',
  },
  20: {
    rank: 16,
    score: 85,
    class: 'low average',
  },
  19: {
    rank: 14,
    score: 84,
    class: 'low average',
  },
  18: {
    rank: 10,
    score: 81,
    class: 'low average',
  },
  17: {
    rank: 9,
    score: 80,
    class: 'low average',
  },
  16: {
    rank: 8,
    score: 79,
    class: 'below average',
  },
  15: {
    rank: 5,
    score: 76,
    class: 'below average',
  },
  14: {
    rank: 5,
    score: 75,
    class: 'below average',
  },
  13: {
    rank: 3,
    score: 72,
    class: 'below average',
  },
  12: {
    rank: 3,
    score: 71,
    class: 'below average',
  },
  11: {
    rank: 2,
    score: 70,
    class: 'below average',
  },
  10: {
    rank: 1,
    score: 67,
    class: 'well below average',
  },
  9: {
    rank: 1,
    score: 66,
    class: 'well below average',
  },
  8: {
    rank: 1,
    score: 64,
    class: 'well below average',
  },
  7: {
    rank: 1,
    score: 62,
    class: 'well below average',
  },
  6: {
    rank: 1,
    score: 60,
    class: 'well below average',
  },
  5: {
    rank: 1,
    score: 59,
    class: 'well below average',
  },
  4: {
    rank: 1,
    score: 56,
    class: 'well below average',
  },
  3: {
    rank: 1,
    score: 55,
    class: 'well below average',
  },
  2: {
    rank: 1,
    score: 54,
    class: 'well below average',
  },
  1: {
    rank: 1,
    score: 51,
    class: 'well below average',
  },
  0: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
};

export default ogConversion;
