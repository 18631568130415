const erConversion = {
  45: {
    rank: 99,
    score: 139,
    class: 'very superior',
  },
  44: {
    rank: 99,
    score: 137,
    class: 'very superior',
  },
  43: {
    rank: 99,
    score: 135,
    class: 'very superior',
  },
  42: {
    rank: 99,
    score: 133,
    class: 'very superior',
  },
  41: {
    rank: 98,
    score: 130,
    class: 'very superior',
  },
  40: {
    rank: 97,
    score: 128,
    class: 'superior',
  },
  39: {
    rank: 96,
    score: 126,
    class: 'superior',
  },
  38: {
    rank: 95,
    score: 124,
    class: 'superior',
  },
  37: {
    rank: 93,
    score: 122,
    class: 'superior',
  },
  36: {
    rank: 90,
    score: 119,
    class: 'high average',
  },
  35: {
    rank: 86,
    score: 116,
    class: 'high average',
  },
  34: {
    rank: 82,
    score: 114,
    class: 'high average',
  },
  33: {
    rank: 79,
    score: 112,
    class: 'high average',
  },
  32: {
    rank: 75,
    score: 110,
    class: 'high average',
  },
  31: {
    rank: 70,
    score: 108,
    class: 'average',
  },
  30: {
    rank: 66,
    score: 106,
    class: 'average',
  },
  29: {
    rank: 61,
    score: 104,
    class: 'average',
  },
  28: {
    rank: 55,
    score: 102,
    class: 'average',
  },
  27: {
    rank: 50,
    score: 100,
    class: 'average',
  },
  26: {
    rank: 45,
    score: 98,
    class: 'average',
  },
  25: {
    rank: 37,
    score: 95,
    class: 'average',
  },
  24: {
    rank: 32,
    score: 93,
    class: 'average',
  },
  23: {
    rank: 27,
    score: 91,
    class: 'average',
  },
  22: {
    rank: 23,
    score: 89,
    class: 'low average',
  },
  21: {
    rank: 18,
    score: 86,
    class: 'low average',
  },
  20: {
    rank: 14,
    score: 84,
    class: 'low average',
  },
  19: {
    rank: 13,
    score: 83,
    class: 'low average',
  },
  18: {
    rank: 9,
    score: 80,
    class: 'low average',
  },
  17: {
    rank: 7,
    score: 78,
    class: 'below average',
  },
  16: {
    rank: 5,
    score: 76,
    class: 'below average',
  },
  15: {
    rank: 4,
    score: 73,
    class: 'below average',
  },
  14: {
    rank: 3,
    score: 71,
    class: 'below average',
  },
  13: {
    rank: 2,
    score: 69,
    class: 'well below average',
  },
  12: {
    rank: 1,
    score: 67,
    class: 'well below average',
  },
  11: {
    rank: 1,
    score: 65,
    class: 'well below average',
  },

  10: {
    rank: 1,
    score: 63,
    class: 'well below average',
  },
  9: {
    rank: 1,
    score: 61,
    class: 'well below average',
  },
  8: {
    rank: 1,
    score: 59,
    class: 'well below average',
  },
  7: {
    rank: 1,
    score: 56,
    class: 'well below average',
  },
  6: {
    rank: 1,
    score: 54,
    class: 'well below average',
  },
  5: {
    rank: 1,
    score: 52,
    class: 'well below average',
  },
  4: {
    rank: 1,
    score: 52,
    class: 'well below average',
  },
  3: {
    rank: 1,
    score: 52,
    class: 'well below average',
  },
  2: {
    rank: 1,
    score: 52,
    class: 'well below average',
  },
  1: {
    rank: 1,
    score: 52,
    class: 'well below average',
  },
  0: {
    rank: 1,
    score: 52,
    class: 'well below average',
  },
};

export default erConversion;
