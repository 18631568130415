/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { Modal } from 'components';
import Button from 'components/Button';
import { FieldsBox, Field, Select, Checkbox } from 'components/Form';

import IState from 'reducers';

import {
  getTutors,
  getRecommendedTutors,
  addRecommendedTutor,
  updateRecommendedTutorSession,
  resetRecommendedTutorAdded,
  deleteRecommendedTutor,
  resetRecommendedTutorDeleted,
} from '../Tutors/tutors.action';
import { getGrades } from '../Home/LearnersAddition/learnersAdditionForm.action';
import { generateActivationLink } from '../Home/home.action';
import {
  getLearnerProfile,
  updateLearnerProfile,
  updateUserProfile,
  getLearnerSessions,
} from './learner.action';

import Scheduler from './Scheduler';
import Sessions from './Sessions';
import PaymentsTable from './PaymentsTable';

import {
  Background,
  RecommendedTutor,
  RecommendedTutorSide,
  SessionCheckBoxContainer,
  AddRecommendedTutor,
  RecommendedTutorModal,
  RecomendedButtonsGroup,
  RecommendedTitle,
  RecomendedHeader,
  TutorsBox,
  LearnerField,
  NoEditable,
  LearnerColumn,
  RecommendedTutorAddedTag,
  RecommendedTutorDeletedTag,
  ButtonBox,
  Highlight,
} from './styles';

import {
  Subtitle,
  LearnersSection,
  SubmitSection,
  SubmitButtons,
  BoxCopy,
  CopyLinkGroup,
  CopiedPopUp,
} from '../Home/styles';

import { initialState, IdbItem, IFormValues } from './learner.interfaces';

export default function Learner() {
  const dispatch = useDispatch();
  const { isMobile } = useSelector((state: IState) => state.devices);
  const [editMode, setEditMode] = useState(false);

  const [tutorSelected, setTutorSelected] = useState({
    value: 0,
    label: '',
    online: false,
  });
  const [errors, setErrors] = useState<Array<string>>(initialState.errors);
  const [showRecommendedTutorModal, setShowRecommendedTutorModal] =
    useState(false);
  const [recommendedTutorAlreadyAdded, setRecommendedTutorAlreadyAdded] =
    useState('');
  const [popUp, setPopUp] = useState(false);
  const [activeLinkInput, setActiveLinkInput] = useState('');

  const [recomendedTutorForDelete, setRecomendedTutorForDelete] = useState({
    aboutMe: '',
    calendarUrl: null,
    email: '',
    id: '',
    firstName: '',
    lastName: '',
    introVideoUrl: null,
    profileImageUrl: null,
    specialist: false,
    titleDegree: null,
  });
  const [showDeleteRecommendedTutorModal, setShowDeleteRecommendedTutorModal] =
    useState(false);

  const [changeModal, setChangeModal] = useState('');
  const [formChanges, setFormChanges] = useState<Array<string>>([]);

  const [scheduleModal, setScheduleModal] = useState(false);
  const [schedulingTutor, setSchedulingTutor] = useState(null);

  const {
    tutorsData,
    recommendedTutors,
    recommendedTutorAdded,
    recommendedTutorDeleted,
    recommendedTutorFailed,
  } = useSelector((state: IState) => state.tutors);

  const {
    actualLearner: {
      alias,
      grade,
      dateCreated,
      userId,
      userEmail,
      userFirstName,
      userLastName,
      totalLeft,
      totalPurchased,
      totalUnscheduled,
      learnerId,
      isActive,
      parentScreenerStatus,
      learnerScreenerStatus,
      screenerReviewStatus,
      sessionDetails,
    },
    activationLink,
  } = useSelector((state: IState) => state.learners);

  const [formValues, setFormValues] = useState<IFormValues>({
    ...initialState.formValues,
    alias,
    firstName: userFirstName,
    lastName: userLastName,
    grade: { value: grade, label: grade },
  });
  const { grades } = useSelector((state: IState) => state.learnersAddition);
  const [showRecommendedTutorAdded, setShowRecommendedTutorAdded] =
    useState(false);
  const [showRecommendedTutorDeleted, setShowRecommendedTutorDeleted] =
    useState(false);

  useEffect(() => {
    dispatch(getLearnerProfile(userId, learnerId));
    dispatch(getTutors({ size: 100 }));
    dispatch(getGrades());
    dispatch(getRecommendedTutors(learnerId));
    dispatch(getLearnerSessions(learnerId));
  }, [dispatch, userId, learnerId]);

  useEffect(() => {
    if (recommendedTutorAdded) {
      setShowRecommendedTutorAdded(true);
      dispatch(getRecommendedTutors(learnerId));
    } else {
      setShowRecommendedTutorAdded(false);
    }
  }, [dispatch, recommendedTutorAdded, learnerId]);

  useEffect(() => {
    if (recommendedTutorDeleted) {
      setShowRecommendedTutorDeleted(true);
      dispatch(getRecommendedTutors(learnerId));
    } else {
      setShowRecommendedTutorDeleted(false);
    }
  }, [dispatch, recommendedTutorDeleted, learnerId]);

  useEffect(() => {
    if (recommendedTutorFailed) {
      setRecommendedTutorAlreadyAdded(recommendedTutorFailed);
    } else {
      setRecommendedTutorAlreadyAdded('');
    }
  }, [recommendedTutorFailed]);

  useEffect(() => {
    if (activationLink) {
      setActiveLinkInput(activationLink);
    }
  }, [activationLink]);

  useEffect(() => {
    const formChanges: Array<string> = [];
    if (formValues.alias !== alias) formChanges.push('alias');
    if (formValues.grade.value !== grade) formChanges.push('grade');
    if (formValues.firstName !== userFirstName) formChanges.push('firstName');
    if (formValues.lastName !== userLastName) formChanges.push('lastName');
    setFormChanges(formChanges);
  }, [formValues, alias, grade, userFirstName, userLastName]);

  const tutorsOptions: Array<IdbItem> = tutorsData.map((e) => ({
    value: e.id,
    label: `${e.firstName} ${e.lastName}`,
    online: e.online,
  }));

  const gradesOptions: Array<IdbItem> = grades
    .map((e) => ({
      value: e.id,
      label: e.name,
    }))
    .sort((a, b) => a.value - b.value);

  const handleChange = (event) => {
    setErrors((prevState) => prevState.filter((e) => e !== event.target.name));
    if (event.target.name === 'email') {
      if (errors.includes('invalidEmail')) {
        setErrors((prevState) => prevState.filter((e) => e !== 'invalidEmail'));
      }
      if (errors.includes('alreadyExist')) {
        setErrors((prevState) => prevState.filter((e) => e !== 'alreadyExist'));
      }
    }

    setFormValues((prevState: IFormValues) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const clearFormChanges = () => {
    setFormValues((prevState: IFormValues) => ({
      ...prevState,
      alias,
      firstName: userFirstName,
      lastName: userLastName,
      grade: { value: grade, label: grade },
    }));

    setChangeModal('');
    setEditMode(false);
  };

  const saveFormChanges = () => {
    const learnerOptions: Record<string, unknown> = {};
    if (formChanges.includes('alias')) learnerOptions.alias = formValues.alias;
    if (!_.isEmpty(learnerOptions))
      dispatch(updateLearnerProfile(learnerOptions, userId, learnerId));

    const userOptions: Record<string, unknown> = {};
    if (formChanges.includes('firstName'))
      userOptions.firstName = formValues.firstName;
    if (formChanges.includes('lastName'))
      userOptions.lastName = formValues.lastName;

    if (!_.isEmpty(userOptions))
      dispatch(updateUserProfile(userOptions, userId));
    setChangeModal('');
    setEditMode(false);
  };

  const handleCloseModal = () => {
    setShowRecommendedTutorModal(false);
    dispatch(resetRecommendedTutorAdded());
  };

  const handleCloseDeleteRecommendedModal = () => {
    setShowDeleteRecommendedTutorModal(false);
    dispatch(resetRecommendedTutorDeleted());
  };

  const optionSelectValidate = (value, options) =>
    options.some((v) => _.isEqual(value, v)) ? value : false;

  const handleSelectRecommendedTutor = (e) => {
    e.preventDefault();
    dispatch(
      addRecommendedTutor(tutorSelected.value, learnerId, tutorSelected.online)
    );
  };

  const handleSetTutorSelected = (e) => {
    setTutorSelected(e);
    dispatch(resetRecommendedTutorAdded());
  };

  const handleShowDeleteTutor = (tutor) => {
    setShowDeleteRecommendedTutorModal(true);
    setRecomendedTutorForDelete(tutor);
  };

  const handleDeleteRecommendedTutor = (e) => {
    e.preventDefault();
    dispatch(deleteRecommendedTutor(learnerId, recomendedTutorForDelete.id));
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(activeLinkInput);
    setPopUp(true);
    const timer = setTimeout(() => {
      setPopUp(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  const cancelChanges = () => {
    if (formChanges.length <= 0) return setEditMode(false);
    return setChangeModal('cancel');
  };

  const saveChanges = () => setChangeModal('save');

  if (!learnerId) return null;

  const formMapping = {
    alias: "Learner's alias",
    firstName: "Parent's first name",
    lastName: "Parent's last name",
  };

  const startScheduling = (tutor) => {
    setScheduleModal(true);
    setSchedulingTutor(tutor);
  };

  const endScheduling = () => {
    setScheduleModal(false);
    setSchedulingTutor(null);
  };

  const updateRecommendedTutor = (target, recommendedTutor) => {
    const recommendedTutorData = {
      tutorId: recommendedTutor.id,
      learnerId,
      online: recommendedTutor.recommendation.online,
      inhome: recommendedTutor.recommendation.inhome,
      [target.name]: target.value !== 'yes',
    };
    dispatch(updateRecommendedTutorSession(recommendedTutorData));
  };

  return (
    <Background mw={900}>
      <Modal
        isOpen={showRecommendedTutorModal}
        closeModal={handleCloseModal}
        full={isMobile}
        mw={800}
      >
        <RecommendedTutorModal>
          <RecomendedButtonsGroup>
            <form onSubmit={handleSelectRecommendedTutor}>
              <FieldsBox>
                <Select
                  label="Tutor:"
                  options={tutorsOptions}
                  name="tutorSelected"
                  value={optionSelectValidate(tutorSelected, tutorsOptions)}
                  placeholder="Select"
                  handleChange={handleSetTutorSelected}
                  error={
                    recommendedTutorAlreadyAdded && recommendedTutorAlreadyAdded
                  }
                />
                <SubmitSection>
                  <SubmitButtons>
                    <Button type="submit">Add</Button>
                  </SubmitButtons>
                </SubmitSection>
              </FieldsBox>
            </form>
          </RecomendedButtonsGroup>
          {showRecommendedTutorAdded && (
            <RecommendedTutorAddedTag>
              Tutor added successfully!
            </RecommendedTutorAddedTag>
          )}
        </RecommendedTutorModal>
      </Modal>
      <Modal
        isOpen={showDeleteRecommendedTutorModal}
        closeModal={handleCloseDeleteRecommendedModal}
        full={isMobile}
        mw={800}
      >
        <Subtitle>Remove this recommended tutor?</Subtitle>
        {`${recomendedTutorForDelete.firstName} ${recomendedTutorForDelete.lastName}`}
        <form onSubmit={handleDeleteRecommendedTutor}>
          {!showRecommendedTutorDeleted && (
            <SubmitSection>
              <SubmitButtons>
                <Button kind="secondary" type="submit">
                  Remove
                </Button>
              </SubmitButtons>
            </SubmitSection>
          )}
        </form>
        {showRecommendedTutorDeleted && (
          <RecommendedTutorDeletedTag>
            Tutor deleted successfully!
          </RecommendedTutorDeletedTag>
        )}
      </Modal>
      <Modal isOpen={!!changeModal} closeModal={() => setChangeModal('')}>
        {!!changeModal && (
          <>
            <div>
              {changeModal === 'save' ? 'Save' : 'Cancel and discard'} changes
              for these fields?
            </div>
            <br />
            <br />
            {formChanges.map((f) => (
              <Highlight key={f}>{formMapping[f]}</Highlight>
            ))}
            <ButtonBox>
              <Button kind="small" onClick={() => setChangeModal('')}>
                No, keep editing
              </Button>
              <Button
                kind="small"
                color="primary"
                margin={8}
                onClick={
                  changeModal === 'save' ? saveFormChanges : clearFormChanges
                }
              >
                Yes {changeModal === 'save' ? 'save' : 'discard'} changes
              </Button>
            </ButtonBox>
          </>
        )}
      </Modal>
      <RecomendedHeader>
        <RecommendedTitle>Learner Info</RecommendedTitle>

        <div>
          {editMode ? (
            <>
              <Button kind="small" onClick={cancelChanges}>
                Cancel
              </Button>
              <Button
                disabled={formChanges.length <= 0}
                kind="small"
                color="primary"
                margin={8}
                onClick={saveChanges}
              >
                Save Changes
              </Button>
            </>
          ) : (
            <Button kind="small" onClick={() => setEditMode(!editMode)}>
              Edit Account
            </Button>
          )}
        </div>
      </RecomendedHeader>
      <LearnersSection>
        {!editMode && (
          <NoEditable>
            <LearnerColumn>
              <LearnerField>
                <b>Parent&apos;s First Name:</b> {userFirstName}
              </LearnerField>
              <LearnerField>
                <b>Parent&apos;s Last Name:</b> {userLastName}
              </LearnerField>
              <LearnerField>
                <b>Parent&apos;s Email:</b> {userEmail}
              </LearnerField>

              <br />
              <br />
              <br />
              <LearnerField>
                <b>Account Active:</b> <b>{isActive.toString()}</b>
              </LearnerField>
              <LearnerField>
                <b>Parent Screener Status:</b>{' '}
                {parentScreenerStatus.toLowerCase()}
              </LearnerField>
              <LearnerField>
                <b>Learner Screener Status:</b>{' '}
                {learnerScreenerStatus.toLowerCase()}
              </LearnerField>
              <LearnerField>
                <b>Screener Review Status:</b>{' '}
                {screenerReviewStatus.toLowerCase()}
              </LearnerField>
            </LearnerColumn>

            <LearnerColumn>
              <LearnerField>
                <b>Learner&apos;s Alias:</b> <Highlight>{alias}</Highlight>
              </LearnerField>
              <LearnerField>
                <b>Grade:</b> {grade}
              </LearnerField>
              <LearnerField>
                <b>Date Created:</b>{' '}
                {moment.utc(dateCreated).local().format('M/DD/YY h:mma')}
              </LearnerField>

              <br />
              <br />
              <br />
              <LearnerField>
                <b>Minutes Left:</b> {totalLeft}
              </LearnerField>
              <LearnerField>
                <b>Minutes Purchased:</b> {totalPurchased}
              </LearnerField>
              <LearnerField>
                <b>Minutes Unscheduled:</b> {totalUnscheduled}
              </LearnerField>
            </LearnerColumn>
          </NoEditable>
        )}
        {!!editMode && (
          <>
            <FieldsBox style={{ flexWrap: 'wrap' }}>
              <Field
                white
                label="Learner's Alias:"
                name="alias"
                type="text"
                handleChange={handleChange}
                value={formValues.alias}
                error={errors.includes('alias')}
              />

              <Select
                white
                label="Current Grade"
                options={gradesOptions}
                value={formValues.grade}
                name="gradesOptions"
                placeholder="Select"
                handleChange={(value) =>
                  handleChange({ target: { name: 'grade', value } })
                }
              />
            </FieldsBox>
            {isActive ? (
              <FieldsBox style={{ flexWrap: 'wrap' }}>
                <Field
                  white
                  label="Parent's First Name:"
                  name="firstName"
                  type="text"
                  handleChange={handleChange}
                  value={formValues.firstName}
                  error={errors.includes('firstName')}
                />
                <Field
                  white
                  label="Parent's Last Name:"
                  name="lastName"
                  type="text"
                  handleChange={handleChange}
                  value={formValues.lastName}
                  error={errors.includes('lastName')}
                />
              </FieldsBox>
            ) : (
              <Highlight>
                <br />
                <br />
                Account must be active to update Parent/User fields
                <br />
                <br />
              </Highlight>
            )}
          </>
        )}
        <br />
        {!!sessionDetails?.length && (
          <Sessions sessionDetails={sessionDetails} />
        )}
        {!isActive && (
          <>
            <br />
            <Subtitle>Activation link:</Subtitle>
            <Button
              type="button"
              kind="small"
              onClick={() => dispatch(generateActivationLink(userId))}
            >
              Generate Link
            </Button>
            <CopyLinkGroup>
              <Field
                type="text"
                name="test"
                full
                readonly
                value={activeLinkInput}
              />
              {popUp && <CopiedPopUp>Copied!</CopiedPopUp>}
              <BoxCopy onClick={handleCopyLink} />
            </CopyLinkGroup>
          </>
        )}
        <br />
        <br />
        {scheduleModal && !!schedulingTutor && (
          <Scheduler
            closeModal={endScheduling}
            tutor={schedulingTutor}
            learnerId={learnerId}
            learnerAlias={alias}
            userEmail={userEmail}
          />
        )}
        {isActive && (
          <TutorsBox>
            <Subtitle> Recommended tutors: </Subtitle>
            <RecommendedTutorSide>
              {recommendedTutors.length && recommendedTutors[0].id ? (
                recommendedTutors.map((recommendedTutor) => (
                  <div key={recommendedTutor.id}>
                    <RecommendedTutor>
                      <div>{`${recommendedTutor.firstName} ${recommendedTutor.lastName}`}</div>

                      <div>
                        <Button
                          type="button"
                          kind="quaternary"
                          color="quaternary"
                          width={142}
                          onClick={() => startScheduling(recommendedTutor)}
                          margin={75}
                        >
                          SCHEDULE
                        </Button>

                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() =>
                            handleShowDeleteTutor(recommendedTutor)
                          }
                        />
                      </div>
                    </RecommendedTutor>

                    <SessionCheckBoxContainer>
                      {recommendedTutor.online && (
                        <Checkbox
                          type="checkbox"
                          name="online"
                          value={
                            recommendedTutor.recommendation.online
                              ? 'yes'
                              : 'no'
                          }
                          checked={recommendedTutor.recommendation.online}
                          handleChange={(event) =>
                            updateRecommendedTutor(
                              event.target,
                              recommendedTutor
                            )
                          }
                          label="Online"
                        />
                      )}
                      {recommendedTutor.inhome && (
                        <Checkbox
                          type="checkbox"
                          name="inhome"
                          value={
                            recommendedTutor.recommendation.inhome
                              ? 'yes'
                              : 'no'
                          }
                          checked={recommendedTutor.recommendation.inhome}
                          handleChange={(event) =>
                            updateRecommendedTutor(
                              event.target,
                              recommendedTutor
                            )
                          }
                          label="In-Home"
                        />
                      )}
                    </SessionCheckBoxContainer>
                  </div>
                ))
              ) : (
                <div>There are not recommended tutors</div>
              )}
            </RecommendedTutorSide>

            <AddRecommendedTutor
              onClick={() => setShowRecommendedTutorModal(true)}
            >
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
              <span>
                <b>Add recommended tutor</b>
              </span>
            </AddRecommendedTutor>
          </TutorsBox>
        )}
        <PaymentsTable />
      </LearnersSection>
    </Background>
  );
}
