/* eslint-disable import/prefer-default-export */
export const initialState = {
  locations: [],
  servicesInterestedIn: [],
  grades: [],
  lastLearnerCreated: 0,
  errors: [],
  showError: false,
  formValues: {
    alias: '',
    totalSessionsPurchased: 0,
  },
};

export interface IFormValues {
  alias: string;
  totalSessionsPurchased: number;
  grade: { value: string; label: string };
  firstName: string;
  lastName: string;
}

export interface ILearner {
  alias: string;
  dateCreated: string;
  defaultUser: boolean;
  grade: string;
  initialPlanPayed: boolean;
  learnerId: string;
  learnerScreenerStatus: string;
  parentScreenerStatus: string;
  profileCompleted: boolean;
  screenerReviewStatus: string;
  totalSessionsLeft: number;
  totalSessionsPurchased: number;
  totalSessionsUnscheduled: number;
  totalSessionsUsed: number;
  userFirstName: string;
  userLastName: string;
}

export interface IdbItem {
  value: number | string;
  label: string;
}
