import csvParser from '../csvParser';

const getIds = (responses) => {
  const respondentId = responses[2][0];
  if (!respondentId) console.error('No respondent id dectected');
  const collectorId = responses[2][1];
  return [respondentId, collectorId];
};

const trimResponses = (responses) => {
  // Remove first columns of metadata from each row
  responses.forEach((row) => row.splice(0, 11));
};

const readResponses = (csv) => {
  const rawResponses = csvParser(csv);
  const responses = rawResponses;
  const [respondentId, collectorId] = getIds(responses);
  trimResponses(responses);
  return { responses, respondentId, collectorId };
};

export default readResponses;
