import {
  LOCATIONS,
  TUTOR_DATA_SUCCESS,
  TUTOR_DATA_FAILURE,
  RESET_TUTOR_ADDED,
  TUTOR_EDIT_DATA_SUCCESS,
  TUTOR_EDIT_DATA_FAILURE,
} from '../pages/Tutors/TutorsAddition/tutorsAdditionForm.action';

const initialState = {
  interests: [],
  grades: [],
  locations: [],
  formResponse: {},
  formEditResponse: {},
  tutorAdded: false,
  tutorEdited: false,
};

export default function learnersAdditionData(state = initialState, action) {
  switch (action.type) {
    case LOCATIONS:
      return {
        ...state,
        locations: action.payload.locations.states,
      };
    case TUTOR_DATA_SUCCESS:
      return {
        ...state,
        tutorAdded: true,
      };
    case TUTOR_EDIT_DATA_SUCCESS:
      return {
        ...state,
        tutorEdited: true,
      };
    case TUTOR_DATA_FAILURE:
      return {
        ...state,
        formResponse: action.payload,
      };
    case TUTOR_EDIT_DATA_FAILURE:
      return {
        ...state,
        formEditResponse: action.payload,
      };
    case RESET_TUTOR_ADDED:
      return {
        ...state,
        tutorAdded: false,
        tutorEdited: false,
      };
    default:
      return state;
  }
}
