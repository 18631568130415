import React, { useContext } from 'react';
import Select from 'react-select';
import { ThemeContext } from 'styled-components';
import { FieldBox, Label, Error } from './common';
import {
  IStylesSelectPropsStyle,
  ISelectComponentProps,
} from './Form.interfaces';

const StylesSelect = (props: IStylesSelectPropsStyle) => {
  const { error, white } = props;
  const theme = useContext(ThemeContext);

  const borderColor = white
    ? theme.colors.main.medium
    : theme.colors.alert.default;

  return {
    control: (provided, state) => ({
      ...provided,
      background:
        state.isFocused || error || white
          ? theme.colors.white.default
          : theme.colors.quaternary.default,
      color: theme.colors.main.default,
      height: '54px',
      border: error || white ? `1px solid ${borderColor}` : '0',
      borderRadius: '8px',
      transition: 'all 0.25s linear',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 15px',
    }),
    dropdownIndicator: (provided, state) => {
      const colorIcon = () => {
        if (error) return theme.colors.alert.default;
        if (state.isFocused) return theme.colors.main.default;
        return theme.colors.secondary.default;
      };
      return {
        ...provided,
        paddingRight: '20px',
        transition: 'all 0.25s linear',
        color: colorIcon(),
      };
    },
    indicatorsContainer: () => ({}),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };
};

export default function SelectComponent(props: ISelectComponentProps) {
  const {
    label,
    options,
    name,
    value,
    placeholder,
    handleChange,
    required,
    disabled,
    error,
    white,
  } = props;

  const customStyles = StylesSelect({ error: !!error, white: !!white });
  return (
    <FieldBox>
      <Label name={name} label={label} />
      <Select
        styles={customStyles}
        classNamePrefix="select"
        components={{
          IndicatorSeparator: () => null,
        }}
        options={options}
        name={name}
        value={value && value}
        onChange={handleChange}
        required={required}
        isDisabled={disabled}
        placeholder={placeholder}
      />
      {error && <Error>{error}</Error>}
    </FieldBox>
  );
}
