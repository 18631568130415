import React from 'react';
import { useSelector } from 'react-redux';

import IState from '../../reducers';
import { BackgroundStyle, Container, Main } from './styles';

export default function Background({
  children,
  mw,
}: {
  children: React.ReactNode;
  mw?: number;
}) {
  const { isMobile } = useSelector((state: IState) => state.devices);
  return (
    <BackgroundStyle isMobile={isMobile}>
      <Main mw={mw}>
        <Container>{children}</Container>
      </Main>
    </BackgroundStyle>
  );
}
