export const SET_DEVICES = 'SET_DEVICES';
export const LOGOUT = 'LOGOUT';
export const CLEAN_USER = 'CLEAN_USER';
export const SET_USER = 'SET_USER';

export const logOut = () => ({
  type: LOGOUT,
});

export const logout = () => {
  localStorage.clear();
  return (dispatch) => {
    dispatch(logOut());
  };
};

export const setDevices = (devices) => ({
  type: SET_DEVICES,
  devices,
});
