const transformSums = (categorySums) => {
  const categories = Object.keys(categorySums);

  const labelRow: string[] = ['', '', ''];
  const sumRow: Array<number | string> = ['', '', ''];

  categories.forEach((c: string) => {
    labelRow.push(c.toUpperCase());
    sumRow.push(categorySums[c]);
  });

  return [labelRow, sumRow];
};

const transformResults = (results) => {
  const labelRow = [
    '',
    '',
    'CEFI Scales',
    'Sum',
    'Scores',
    'Rank %',
    'Classify',
  ];
  const resultRows = results.map((r) => [
    '',
    '',
    r.label,
    r.sum,
    r.score,
    `${r.rank}%`,
    r.class,
  ]);
  return [labelRow, ...resultRows];
};

const getReportRows = ({ responses, categorySums, results }) => {
  const sumRows = transformSums(categorySums);
  const reportRows = transformResults(results);
  return [...reportRows, [], [], ...sumRows, [], [], ...responses];
};

export default getReportRows;
