import csvParser from '../csvParser';

let ageVersion;

// Currently the numbers indexes for each question are off by 4
// This is because there are 4 "questions" on the survey that are not included in the responses, such as "What is your name"
// This helper transforms `Q5: What is your answer` => `Q1: What is your answer?`
const updateNumber = (question: string) => {
  const shiftAmount = ageVersion ? 3 : 4;
  const colonIndex = question.indexOf(':');
  const arr = question.split('');
  const numArr = arr.splice(0, colonIndex);
  const num = parseInt(numArr.splice(1).join('')) - shiftAmount;
  return `Q${num}${arr.join('')}`;
};

const detectVersion = (questions: Array<string | false>) => {
  let ageVersion = false;
  const firstQuestion = questions[0];
  if (!firstQuestion) return ageVersion;
  if (firstQuestion.includes(`kid's age range`)) ageVersion = true;
  return ageVersion;
};

const cleanResponses = (
  rawResponses: Array<Array<string>>
): Array<Array<string | false | null>> => {
  const questions = rawResponses[0]
    .map((cell) => cell.startsWith('Q') && cell)
    .filter(Boolean);

  ageVersion = detectVersion(questions);

  let ageNeeded = ageVersion;
  const ageIndex = 4;

  const values = rawResponses[2]
    .map((cell, idx) => {
      const getAge = ageNeeded && idx === ageIndex && cell.includes('/2');

      if (cell.includes('/5') || getAge) {
        if (getAge) ageNeeded = false;
        const value = cell.split('/')[0];
        return value;
      }
      return null;
    })
    .filter(Boolean);

  const rows = questions.map((q, idx) => [
    updateNumber(q as string),
    values[idx],
  ]);

  if (ageNeeded) console.error('age range not detected');
  return rows;
};

const readResponses = (csv) => {
  const rawResponses = csvParser(csv);

  const responderId = rawResponses[2][0];
  const responses = cleanResponses(rawResponses);

  let ageGroup = 1;
  if (ageVersion) {
    const ageResponse = responses.shift();
    if (!ageResponse || !ageResponse[1])
      return { error: 'age range not detected' };
    ageGroup = parseInt(ageResponse[1]);
    if (![1, 2].includes(ageGroup)) return { error: 'age range not valid' };
  }
  return { responses, ageGroup, responderId };
};

export default readResponses;
