const plConversion = {
  55: {
    rank: 99,
    score: 137,
    class: 'very superior',
  },
  54: {
    rank: 99,
    score: 136,
    class: 'very superior',
  },
  53: {
    rank: 99,
    score: 134,
    class: 'very superior',
  },
  52: {
    rank: 98,
    score: 132,
    class: 'very superior',
  },
  51: {
    rank: 98,
    score: 130,
    class: 'very superior',
  },
  50: {
    rank: 97,
    score: 128,
    class: 'superior',
  },
  49: {
    rank: 96,
    score: 127,
    class: 'superior',
  },
  48: {
    rank: 95,
    score: 125,
    class: 'superior',
  },
  47: {
    rank: 94,
    score: 123,
    class: 'superior',
  },
  46: {
    rank: 92,
    score: 121,
    class: 'superior',
  },
  45: {
    rank: 91,
    score: 120,
    class: 'superior',
  },
  44: {
    rank: 88,
    score: 118,
    class: 'high average',
  },
  43: {
    rank: 86,
    score: 116,
    class: 'high average',
  },
  42: {
    rank: 84,
    score: 115,
    class: 'high average',
  },
  41: {
    rank: 81,
    score: 113,
    class: 'high average',
  },
  40: {
    rank: 79,
    score: 112,
    class: 'high average',
  },
  39: {
    rank: 75,
    score: 110,
    class: 'high average',
  },
  38: {
    rank: 70,
    score: 108,
    class: 'average',
  },
  37: {
    rank: 66,
    score: 106,
    class: 'average',
  },
  36: {
    rank: 63,
    score: 105,
    class: 'average',
  },
  35: {
    rank: 58,
    score: 103,
    class: 'average',
  },
  34: {
    rank: 53,
    score: 101,
    class: 'average',
  },
  33: {
    rank: 50,
    score: 100,
    class: 'average',
  },
  32: {
    rank: 45,
    score: 98,
    class: 'average',
  },
  31: {
    rank: 37,
    score: 95,
    class: 'average',
  },
  30: {
    rank: 34,
    score: 94,
    class: 'average',
  },
  29: {
    rank: 30,
    score: 92,
    class: 'average',
  },
  28: {
    rank: 27,
    score: 91,
    class: 'average',
  },
  27: {
    rank: 21,
    score: 88,
    class: 'low average',
  },
  26: {
    rank: 19,
    score: 87,
    class: 'low average',
  },
  25: {
    rank: 16,
    score: 85,
    class: 'low average',
  },
  24: {
    rank: 14,
    score: 84,
    class: 'low average',
  },
  23: {
    rank: 10,
    score: 81,
    class: 'low average',
  },
  22: {
    rank: 9,
    score: 80,
    class: 'low average',
  },
  21: {
    rank: 7,
    score: 78,
    class: 'below average',
  },
  20: {
    rank: 6,
    score: 77,
    class: 'below average',
  },
  19: {
    rank: 4,
    score: 74,
    class: 'below average',
  },
  18: {
    rank: 4,
    score: 73,
    class: 'below average',
  },
  17: {
    rank: 3,
    score: 72,
    class: 'below average',
  },
  16: {
    rank: 2,
    score: 70,
    class: 'below average',
  },
  15: {
    rank: 1,
    score: 67,
    class: 'well below average',
  },
  14: {
    rank: 1,
    score: 66,
    class: 'well below average',
  },
  13: {
    rank: 1,
    score: 64,
    class: 'well below average',
  },
  12: {
    rank: 1,
    score: 63,
    class: 'well below average',
  },
  11: {
    rank: 1,
    score: 60,
    class: 'well below average',
  },
  10: {
    rank: 1,
    score: 59,
    class: 'well below average',
  },
  9: {
    rank: 1,
    score: 57,
    class: 'well below average',
  },
  8: {
    rank: 1,
    score: 56,
    class: 'well below average',
  },
  7: {
    rank: 1,
    score: 53,
    class: 'well below average',
  },
  6: {
    rank: 1,
    score: 52,
    class: 'well below average',
  },
  5: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  4: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  3: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  2: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  1: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  0: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
};

export default plConversion;
