import { HomeIcon, ProfileIcon, ReportsIcon } from './icons';

const NavbarData = [
  {
    label: 'Learners',
    link: '/home',
    icon: ProfileIcon,
  },
  {
    label: 'Tutors',
    link: '/tutors',
    icon: HomeIcon,
  },
  {
    label: 'Reports',
    link: '/reports',
    icon: ReportsIcon,
  },
];

export default NavbarData;
