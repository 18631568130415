/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import BackgroundImg from '../../assets/images/background-home.jpg';

export const BackgroundStyle = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  width: 100vw;
  ${({ isMobile }) =>
    !isMobile &&
    ` background: url(${BackgroundImg});
      background-size: cover; `}
`;

export const Main = styled.div<{ mw?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.colors.white.default};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  max-width: ${({ mw }) => (mw ? `${mw}px` : '90vw')};
  box-sizing: border-box;
  margin-top: 30px;
  padding: 20px;

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    margin-top: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
`;
