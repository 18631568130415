import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import IState from 'reducers';

import { PaymentHeader, TableWrapper, TableRow, Cell } from './styles';

interface PaymentRow {
  date: string;
  sessionCount: number;
  amount: string;
  promoCode: string;
  promoDiscount: string;
}

type TableField =
  | 'date'
  | 'sessionCount'
  | 'amount'
  | 'promoCode'
  | 'promoDiscount';

interface SortOrder {
  field: TableField;
  direction: 'asc' | 'desc';
}

export default function PaymentsTable() {
  const {
    actualLearner: { payments },
  } = useSelector((state: IState) => state.learners);

  const [paymentRows, setPaymentRows] = useState<PaymentRow[]>([]);
  const [sortOrder, setSortOrder] = useState<SortOrder>({
    field: 'date',
    direction: 'desc',
  });

  useEffect(() => {
    const rawRows = payments?.map((p) => ({
      date: new Date(p.paymentDate),
      sessionCount: p.details[0].quantity,
      amount: p.paymentAmount,
      promoCode: p.promoCode?.code || '',
      promoDiscount: p.promoCode?.discount || 0,
      promoDiscountType: p.promoCode?.discountType.toLowerCase() || '',
    }));

    const sorted = orderBy(rawRows, sortOrder.field, [sortOrder.direction]);

    const getPromoDiscount = (promo) => {
      if (!promo.promoDiscount) return '';
      if (promo.promoDiscountType === 'fixed')
        return `$ ${promo.promoDiscount}`;
      if (promo.promoDiscountType === 'percentage')
        return `${promo.promoDiscount}%`;
      return '';
    };

    const rows = sorted.map((p) => ({
      date: p.date.toLocaleString(),
      sessionCount: p.sessionCount,
      amount: `$ ${p.amount.toFixed(2)}`,
      promoCode: p.promoCode,
      promoDiscount: getPromoDiscount(p),
    }));

    setPaymentRows(rows);
  }, [payments, sortOrder]);

  const updateSortOrder = (field: TableField) => {
    const newSortOrder = { ...sortOrder };
    if (field === newSortOrder.field)
      newSortOrder.direction = sortOrder.direction === 'asc' ? 'desc' : 'asc';
    else {
      newSortOrder.field = field;
      newSortOrder.direction = 'desc';
    }

    setSortOrder(newSortOrder);
  };

  const columns: { label: string; field: TableField }[] = [
    { label: 'Date', field: 'date' },
    { label: 'Sessions', field: 'sessionCount' },
    { label: 'Amount', field: 'amount' },
    { label: 'Promo Code', field: 'promoCode' },
    { label: 'Promo Discount', field: 'promoDiscount' },
  ];

  return (
    <>
      <PaymentHeader>Payments</PaymentHeader>
      <TableWrapper>
        <TableRow>
          {columns.map((c) => (
            <Cell onClick={() => updateSortOrder(c.field)} key={c.field}>
              <div>
                {c.label}
                {sortOrder.field === c.field &&
                  (sortOrder.direction === 'desc' ? (
                    <FontAwesomeIcon icon={faAngleDown} />
                  ) : (
                    <FontAwesomeIcon icon={faAngleUp} />
                  ))}
              </div>
            </Cell>
          ))}
        </TableRow>

        {paymentRows.length <= 0 && (
          <div>
            <br />
            <br />
            No payments made yet
          </div>
        )}

        {paymentRows.map((row) => (
          <TableRow key={row.date}>
            {Object.keys(row).map((ck) => (
              <Cell key={ck}>{row[ck]}</Cell>
            ))}
          </TableRow>
        ))}
      </TableWrapper>
    </>
  );
}
