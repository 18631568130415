import styled from 'styled-components';

export const MobileWrapper = styled.div`
  z-index: 5;
  padding-left: 24px;
  display: none;
  @media only screen and (max-width: 720px) {
    position: absolute;
    top: 35px;
    display: block;
  }
`;

export const NavMenu = styled.nav<{ active: boolean }>`
  background-color: ${({ theme }) => theme.colors.secondary.default};
  position: fixed;
  height: 100vh;
  left: ${({ active }) => (active ? '0' : '-100%')};
  top: 0;
  transition: 500ms;
  width: 250px;
  padding: 37px 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MobileNavWrapper = styled.div<{ active: boolean }>`
  background: rgba(51, 51, 51, 0.5);
  position: fixed;
  height: 100vh;
  width: 100%;
  left: ${({ active }) => (active ? '0' : '-100%')};
  top: 0;
  ul {
    list-style: none;
    padding: 0;
  }
`;

export const OpenTrigger = styled.div<{ background: string }>`
  background: url(${({ background }) => background}) no-repeat top center;
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LinkWrapper = styled.div<{ showSidebar?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 25px 0;

  span {
    color: ${({ theme }) => theme.colors.main.default};
    display: inline-block;
    margin-left: 0.5rem;
    @media only screen and (max-width: 720px) {
      color: ${({ theme }) => theme.colors.white.default};
    }
  }

  svg {
    position: relative;
    max-width: 1.5rem;
  }
`;

export const Title = styled.span`
  display: block;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white.default};
  margin: 40px 0;
  padding: 0 10px;
`;

export const LogoContainer = styled.div`
  width: 145px;
  img {
    width: 100%;
  }
`;

export const CloseContainer = styled.div<{ background: string }>`
  background: url(${({ background }) => background}) no-repeat top center;
  width: 17px;
  height: 17px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 80px;
  background-color: ${({ theme }) => theme.colors.white.default};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 32px;
`;

export const NavWrapper = styled.nav`
  display: flex;
  justify-content: flex-end;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    li {
      padding: 0 20px;
      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.white.default};

        &.active {
          font-weight: bold;
          span {
            border-bottom: 3px solid
              ${({ theme }) => theme.colors.secondary.default};
          }
        }
      }
    }
  }
`;

export const LastLink = styled.div`
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white.default};

    &.active {
      font-weight: bold;
      span {
        border-bottom: 3px solid
          ${({ theme }) => theme.colors.secondary.default};
      }
    }
  }
`;

export const SignOutButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  color: ${({ theme }) => theme.colors.main.default};
  padding: 6px 10px;
  margin: 0;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  @media only screen and (max-width: 720px) {
    color: ${({ theme }) => theme.colors.white.default};
  }
`;

export const ContainerSignOut = styled.div`
  width: 100%;
`;
