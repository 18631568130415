import axios from 'axios';

export const INTERESTS_SERVICES = 'INTERESTS_SERVICES';
export const GRADES = 'GRADES';
export const LOCATIONS = 'LOCATIONS';
export const FORM_DATA_SEND = 'FORM_DATA_SEND';
export const FORM_DATA_FAILURE = 'FORM_DATA_FAILURE';
export const FORM_DATA_SUCCESS = 'FORM_DATA_SUCCESS';
export const RESET_USER_ADDED = 'RESET_USER_ADDED';
const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/`;

const interestedSuccess = (interests) => ({
  type: INTERESTS_SERVICES,
  payload: {
    interests,
  },
});

const gradesSuccess = (grades) => ({
  type: GRADES,
  payload: {
    grades,
  },
});

const locationsSuccess = (locations) => ({
  type: LOCATIONS,
  payload: {
    locations,
  },
});

const formDataSend = () => ({
  type: FORM_DATA_SEND,
});

const formDataSuccess = () => ({
  type: FORM_DATA_SUCCESS,
});

export const formDataFailure = (error) => ({
  type: FORM_DATA_FAILURE,
  payload: {
    error,
  },
});

export const resetUserAdded = () => ({
  type: RESET_USER_ADDED,
});

export const getInterestedServices = () => (dispatch) => {
  axios.get(`${HOST_PROFILE}interest_service`).then((res) => {
    dispatch(interestedSuccess(res.data));
  });
};
export const getGrades = () => (dispatch) => {
  axios.get(`${HOST_PROFILE}grade`).then((res) => {
    dispatch(gradesSuccess(res.data));
  });
};
export const getLocations = () => (dispatch) => {
  axios.get(`${HOST_PROFILE}location`).then((res) => {
    dispatch(locationsSuccess(res.data));
  });
};

export const sendLearnerAdditionData = (learnersAdditionData) => (dispatch) => {
  dispatch(formDataSend());
  axios
    .post(`${HOST_PROFILE}admin/user`, learnersAdditionData, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    .then(() => {
      dispatch(formDataSuccess());
    })
    .catch((err) => {
      const message =
        err.response.data.erroCode === '409' ? 'alreadyExist' : err.message;
      dispatch(formDataFailure(message));
    });
};
