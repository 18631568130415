import styled from 'styled-components/';

export const PaymentHeader = styled.div`
  margin-top: 7rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 500;
`;

export const TableWrapper = styled.div`
  position: relative;
  overflow: scroll;
`;

export const TableRow = styled.div`
  position: relative;
  display: inline-flex;
  width: auto;
  min-width: 100%;

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.quaternary.light};
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.white.default};
  }

  &:first-child {
    background-color: ${({ theme }) => theme.colors.secondary.default};
    color: ${({ theme }) => theme.colors.white.default};
  }
`;

export const Cell = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1rem;
  flex-grow: 1;
  cursor: pointer;
  user-select: none;
  min-width: 4rem;

  &:first-child {
    justify-content: flex-start;
    white-space: nowrap;
    min-width: 12rem;
  }

  &:last-child {
    min-width: 8rem;
  }

  div {
    position: relative;
  }

  svg {
    position: absolute;
    right: -1rem;
  }
`;
