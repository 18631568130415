import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';

import { ISessionDetail } from 'reducers/reducer.interfaces';

import {
  SessionsWrapper,
  SessionTile,
  SessionName,
  SessionTutor,
  SessionDate,
} from './styles';

interface Props {
  sessionDetails: ISessionDetail[];
}

interface ISession {
  id: string;
  startDate: string;
  endDate: string;
  sessionName: string;
  externalMeetingId: string;
  learnerId: string;
  userId: string;
  sessionStatus: {
    name: string;
  };
  tutor: {
    firstName: string;
    lastName: string;
    id: string;
  };
}

export default function Sessions({ sessionDetails }: Props) {
  const [upcomingSessions, setUpcomingSessions] = useState<ISession[]>([]);

  useEffect(() => {
    let allSessions: ISession[] = [];

    sessionDetails.forEach((sd) => {
      allSessions = allSessions.concat(sd.sessions);
    });

    const upcomingSessions = allSessions.filter(
      (s) => s.sessionStatus.name.toLowerCase() === 'scheduled'
    );
    // const completedSessions = allSessions.filter(s => s.sessionStatus.name.toLowerCase() === 'completed');

    setUpcomingSessions(upcomingSessions);
  }, [sessionDetails]);
  return (
    <SessionsWrapper>
      <h2>Upcoming Sessions</h2>
      {upcomingSessions.length < 1 && <div>No upcoming sessions</div>}
      {upcomingSessions.map((s: ISession) => (
        <SessionTile key={s.id}>
          <SessionName>{s.sessionName}</SessionName>
          <SessionTutor>
            with {s.tutor.firstName} {s.tutor.lastName}
          </SessionTutor>

          <SessionDate>
            <FontAwesomeIcon icon={faCalendarAlt} />
            {moment(s.startDate).format('ddd, MMM DD')}
          </SessionDate>
          <SessionDate>
            <FontAwesomeIcon icon={faClock} />
            {moment.utc(s.startDate).local().format('hh:mmA')} -{' '}
            {moment.utc(s.endDate).local().format('hh:mmA')}
          </SessionDate>
        </SessionTile>
      ))}
    </SessionsWrapper>
  );
}
