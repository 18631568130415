import axios from 'axios';

export const LOCATIONS = 'LOCATIONS';
export const TUTOR_DATA_SEND = 'TUTOR_DATA_SEND';
export const TUTOR_EDIT_DATA_SEND = 'TUTOR_EDIT_DATA_SEND';
export const TUTOR_DATA_FAILURE = 'TUTOR_DATA_FAILURE';
export const TUTOR_EDIT_DATA_FAILURE = 'TUTOR_EDIT_DATA_FAILURE';
export const TUTOR_DATA_SUCCESS = 'TUTOR_DATA_SUCCESS';
export const TUTOR_EDIT_DATA_SUCCESS = 'TUTOR_EDIT_DATA_SUCCESS';
export const RESET_TUTOR_ADDED = 'RESET_TUTOR_ADDED';
const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/`;

const locationsSuccess = (locations) => ({
  type: LOCATIONS,
  payload: {
    locations,
  },
});

const tutorDataSend = () => ({
  type: TUTOR_DATA_SEND,
});

const tutorEditaDataSend = () => ({
  type: TUTOR_EDIT_DATA_SEND,
});

const tutorFormDataSuccess = () => ({
  type: TUTOR_DATA_SUCCESS,
});

const tutorFormEditDataSuccess = () => ({
  type: TUTOR_EDIT_DATA_SUCCESS,
});

export const tutorFormDataFailure = (error) => ({
  type: TUTOR_DATA_FAILURE,
  payload: {
    error,
  },
});

export const tutorFormEditDataFailure = (error) => ({
  type: TUTOR_EDIT_DATA_FAILURE,
  payload: {
    error,
  },
});

export const resetTutorAdded = () => ({
  type: RESET_TUTOR_ADDED,
});

export const getLocations = () => (dispatch) => {
  axios.get(`${HOST_PROFILE}location`).then((res) => {
    dispatch(locationsSuccess(res.data));
  });
};

export const sendTutorAdditionData = (tutorsAdditionData) => (dispatch) => {
  dispatch(tutorDataSend());
  axios
    .post(`${HOST_PROFILE}admin/tutor`, tutorsAdditionData, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    .then(() => {
      dispatch(tutorFormDataSuccess());
    })
    .catch((err) => {
      dispatch(
        tutorFormDataFailure(
          err.response.data.errorMessage
            ? err.response.data.errorMessage
            : 'error'
        )
      );
    });
};

export const sendTutorEditData =
  (tutorsAdditionData, tutorId) => (dispatch) => {
    dispatch(tutorEditaDataSend());
    axios
      .patch(`${HOST_PROFILE}admin/tutor/${tutorId}`, tutorsAdditionData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(() => {
        dispatch(tutorFormEditDataSuccess());
      })
      .catch((err) => {
        dispatch(tutorFormEditDataFailure(err.message));
      });
  };
