/* eslint-disable no-underscore-dangle */
import {
  GET_TUTORS_SUCCESS,
  GET_RECOMMENDED_TUTORS_SUCCESS,
  RESET_RECOMMENDED_TUTOR_ADDED,
  RECOMMENDED_TUTOR_ADDED,
  RECOMMENDED_TUTOR_FAILED,
  RECOMMENDED_TUTOR_DELETED,
  RESET_RECOMMENDED_TUTOR_DELETED,
} from '../pages/Tutors/tutors.action';

const initialState = {
  tutorsData: [
    {
      aboutMe: '',
      calendarUrl: '',
      email: false,
      firstName: '',
      id: '',
      introVideoUrl: '',
      lastName: '',
      profileImageUrl: '',
      specialist: false,
      state: 0,
      titleDegree: '',
    },
  ],
  page: {
    number: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0,
  },
  recommendedTutors: [
    {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      profileImageUrl: null,
      titleDegree: null,
      introVideoUrl: null,
      aboutMe: '',
      calendarUrl: null,
      specialist: false,
    },
  ],
  recommendedTutorAdded: false,
  recommendedTutorDeleted: false,
  recommendedTutorFailed: '',
};

export default function devices(state = initialState, action) {
  switch (action.type) {
    case GET_TUTORS_SUCCESS:
      return {
        ...state,
        tutorsData: action.tutors._embedded.tutorProfileResourceList
          ? action.tutors._embedded.tutorProfileResourceList
          : [],
        page: action.tutors.page,
      };
    case GET_RECOMMENDED_TUTORS_SUCCESS:
      return {
        ...state,
        recommendedTutors: action.recommendedTutors,
      };
    case RECOMMENDED_TUTOR_ADDED:
      return {
        ...state,
        recommendedTutorAdded: true,
        recommendedTutorFailed: '',
      };
    case RESET_RECOMMENDED_TUTOR_ADDED:
      return {
        ...state,
        recommendedTutorAdded: false,
        recommendedTutorFailed: '',
      };
    case RECOMMENDED_TUTOR_FAILED:
      return {
        ...state,
        recommendedTutorFailed: action.err,
      };
    case RECOMMENDED_TUTOR_DELETED:
      return {
        ...state,
        recommendedTutorDeleted: true,
      };
    case RESET_RECOMMENDED_TUTOR_DELETED:
      return {
        ...state,
        recommendedTutorDeleted: false,
      };
    default:
      return state;
  }
}
