import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import IState from 'reducers/reducer.interfaces';

export const ImageContent = styled.div<{ size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => (size ? `${size}px` : '36px')};
  width: ${({ size }) => (size ? `${size}px` : '36px')};
  font-size: ${({ size }) => (size ? `${size}px` : '36px')};
  background-color: ${({ theme }) => theme.colors.primary.dark};
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 50%;
  margin: 0 10px;
  overflow: hidden;
  box-sizing: border-box;

  svg {
    height: ${({ size }) => (size ? `${size}px` : '36px')};
    width: ${({ size }) => (size ? `${size}px` : '36px')};
  }

  i {
    color: ${({ theme }) => theme.colors.tertiary.medium};
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 0.7em;
    transform: translate(0.5px, 0px);
    font-style: normal;
  }
`;

export function UserIcon({ size, letter }: { size?: number; letter?: string }) {
  const {
    actualLearner: { alias },
  } = useSelector((state: IState) => state.learners);

  return (
    <ImageContent size={size}>
      {/* <IconUser /> */}
      <i>{letter || alias.charAt(0)}</i>
    </ImageContent>
  );
}
