import translateOldLabels from './translateOldLabels';

const getLabel = (string) => {
  const firstColonIndex = string.indexOf(':');
  // Get label text up to first colon
  // remove #  symbol and quotation mark
  const label = string
    .slice(0, firstColonIndex)
    .replace('#', '')
    .replace('"', '');

  return translateOldLabels(label);
};

export default getLabel;
