import React, { useState, useEffect, useRef } from 'react';

import spreadsheetIcon from 'assets/icons/spreadsheet.svg';

import Chart from '../Chart';
import UploadComponent from '../UploadComponent';

import downloadReport from '../downloadReport';

import generateParentScreenerReport from './generateParentScreenerReport';

import * as styles from '../reports.module.scss';

interface Result {
  rank: number;
  score: number;
  class: string;
  sum: number;
  label: string;
}

export default function ParentScreenerReport() {
  const [responsesFile, setResponsesFile] = useState<File | null>(null);
  const [reportRows, setReportRows] = useState<string[][]>([]);
  const [reportContent, setContent] = useState<ArrayBuffer | string>('');
  const [excludedQuestions, setExcludedQuestions] = useState<number[]>([]);
  const [results, setResults] = useState<Result[]>([]);
  const [ageLabel, setAgeLabel] = useState('');
  const [responderId, setResponderId] = useState<string>('');

  const resultsRef = useRef(null);

  useEffect(() => {
    if (!reportContent) return;
    const { reportRows, excludedQuestions, results, ageLabel, responderId } =
      generateParentScreenerReport(reportContent);

    setReportRows(reportRows || []);
    setExcludedQuestions(excludedQuestions || []);
    setResults(results || []);
    setAgeLabel(ageLabel || '');
    setResponderId(responderId || '');
  }, [reportContent]);

  const reset = () => {
    setResponsesFile(null);
    setReportRows([]);
    setContent('');
    setExcludedQuestions([]);
    setResults([]);
    setAgeLabel('');
    setResponderId('');
  };

  return (
    <div className={styles.reportContainer}>
      <div className={styles.row}>
        <div>
          <h2>Parent Screener</h2>

          {responsesFile && (
            <div className={styles.reset} onClick={reset}>
              Reset
            </div>
          )}
        </div>

        <UploadComponent
          responsesFile={responsesFile}
          setResponsesFile={setResponsesFile}
          setContent={(content) => setContent(content)}
          contentPresent={!!reportContent}
          report="parentScreener"
        />

        {reportRows.length > 0 && (
          <div className={styles.downloadRow}>
            <div className={styles.fileCard}>
              <img src={spreadsheetIcon} alt="file icon" />
              <strong>parent_screener_{responderId}.csv</strong>
              <div>{new Date().toLocaleString()}</div>
            </div>
            <div
              className={styles.downloadButton}
              onClick={() =>
                downloadReport('parentScreener', reportRows, responderId)
              }
            >
              Download Report
            </div>
          </div>
        )}
      </div>

      {reportRows.length > 0 && (
        <div className={styles.resultsContainer} ref={resultsRef}>
          <div className={styles.excludedQuestions}>
            Questions not included in scoring: &nbsp;{' '}
            {excludedQuestions.map(
              (q, idx) =>
                `${q}${idx !== excludedQuestions.length - 1 ? ', ' : ''}`
            )}
          </div>

          {!!results.length && (
            <Chart
              results={results}
              ageLabel={ageLabel}
              resultsRef={resultsRef}
            />
          )}
        </div>
      )}
    </div>
  );
}
