import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

export const SEND_LOGIN = 'SEND_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

const FormLoginSend = () => ({
  type: SEND_LOGIN,
});

const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const loginFailure = (err) => ({
  type: LOGIN_FAILURE,
  err,
});

export const sendLoginData = (data) => (dispatch) => {
  dispatch(FormLoginSend());

  const authenticationData = {
    Username: data.user,
    Password: data.password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const poolData = {
    UserPoolId: process.env.REACT_APP_AWS_POOL_ID || '',
    ClientId: process.env.REACT_APP_AWS_CLIENT_ID || '',
  };
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: data.user,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess(result) {
      if (result.getAccessToken().payload['cognito:groups'][0] === 'Admins') {
        const idToken = result.getIdToken();
        const refreshToken = result.getRefreshToken();
        const id = idToken.payload['custom:learnfully_user_id'];
        localStorage.removeItem('token');
        localStorage.setItem('token', idToken.getJwtToken());
        localStorage.setItem('userID', id);
        localStorage.setItem('refreshToken', refreshToken.getToken());
        dispatch(loginSuccess());
      } else {
        dispatch(loginFailure('Access denied'));
      }
    },

    onFailure(err) {
      dispatch(loginFailure(err.message));
    },
  });
};
