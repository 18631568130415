import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLocations,
  sendTutorAdditionData,
  resetTutorAdded,
  tutorFormDataFailure,
  sendTutorEditData,
} from './tutorsAdditionForm.action';
import { getTutors } from '../tutors.action';
import Button from '../../../components/Button';
import {
  FieldsBox,
  Field,
  TextField,
  Select,
  Error,
  Checkbox,
  FieldBox,
} from '../../../components/Form';
import {
  Title,
  Subtitle,
  CheckboxContainer,
  SubmitSection,
  SubmitButtons,
  TutorAddedTag,
} from '../styles';
import {
  IFormData,
  IdbItem,
  IFormValues,
  IFormEdit,
  initialState,
} from './TutorsAddition.interfaces';
import IState from '../../../reducers';

export default function TutorForm({ editMode }: { editMode: IFormEdit }) {
  const dispatch = useDispatch();
  const { locations, tutorAdded, formResponse, tutorEdited } = useSelector(
    (state: IState) => state.tutorsAddition
  );

  const [errors, setErrors] = useState<Array<string>>(initialState.errors);
  const [showError, setShowError] = useState<boolean>(initialState.showError);
  const [formValues, setFormValues] = useState<IFormValues>(
    initialState.formValues
  );
  const [showTutorAdded, setShowTutorAdded] = useState(false);
  const [showTutorEdited, setShowTutorEdited] = useState(false);
  const myRef = useRef<HTMLHeadingElement>(null);

  useMemo(() => {
    if (editMode.id) {
      const stateItem = _.find(locations, (e) => e.id === editMode.state) || {
        id: '',
        name: '',
      };
      const editData = {
        ...editMode,
        stateOfResidence: { value: +stateItem.id, label: stateItem.name },
        specialist: editMode.specialist ? 'yes' : 'no',
        online: editMode.online ? 'yes' : 'no',
        inhome: editMode.inhome ? 'yes' : 'no',
      };
      setFormValues(editData);
    }
  }, [locations, editMode]);

  useEffect(() => {
    const handleShowError = () => {
      if (formResponse.error) {
        if (
          formResponse.error.includes('The calendar url provided already exist')
        ) {
          setErrors((prevState) => [...prevState, 'calendarAlreadyExist']);
        } else if (
          formResponse.error.includes('The email provided already exist')
        ) {
          setErrors((prevState) => [...prevState, 'emailAlreadyExist']);
        } else {
          setShowError(true);
          setTimeout(() => setShowError(false), 5000);
        }
        setTimeout(() => dispatch(tutorFormDataFailure('')), 3000);
        myRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    };
    dispatch(getLocations());
    handleShowError();
  }, [dispatch, formResponse, showError]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTutorAdded(false);
      dispatch(resetTutorAdded());
    }, 4000);
    if (tutorAdded) {
      setErrors(initialState.errors);
      setShowError(initialState.showError);
      setFormValues(initialState.formValues);
      setShowTutorAdded(true);
      dispatch(getTutors());
    }
    return () => {
      clearTimeout(timer);
    };
  }, [tutorAdded, dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTutorEdited(false);
      dispatch(resetTutorAdded());
    }, 4000);
    if (tutorEdited) {
      setErrors(initialState.errors);
      setShowError(initialState.showError);
      setFormValues(initialState.formValues);
      setShowTutorEdited(true);
      dispatch(getTutors());
    }
    return () => {
      clearTimeout(timer);
    };
  }, [tutorEdited, dispatch]);

  const handleChange = (event) => {
    setErrors((prevState) => prevState.filter((e) => e !== event.target.name));
    if (event.target.name === 'email') {
      if (errors.includes('invalidEmail')) {
        setErrors((prevState) => prevState.filter((e) => e !== 'invalidEmail'));
      }
      if (errors.includes('emailAlreadyExist')) {
        setErrors((prevState) =>
          prevState.filter((e) => e !== 'emailAlreadyExist')
        );
      }
      if (errors.includes('calendarAlreadyExist')) {
        setErrors((prevState) =>
          prevState.filter((e) => e !== 'calendarAlreadyExist')
        );
      }
    }

    if (['online', 'inhome'].includes(event.target.name)) {
      const newValue = event.target.value === 'yes' ? 'no' : 'yes';
      setFormValues((prevState: IFormValues) => ({
        ...prevState,
        [event.target.name]: newValue,
      }));
    } else
      setFormValues((prevState: IFormValues) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
  };
  const handleChangeStateOfResidence = (event) => {
    setErrors((prevState) => prevState.filter((e) => e !== 'stateOfResidence'));
    setFormValues((prevState: IFormValues) => ({
      ...prevState,
      stateOfResidence: event,
    }));
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const preSubmit = async () => {
    let count = 0;

    if (!formValues.firstName && !errors.includes('firstName')) {
      setErrors((prevState) => [...prevState, 'firstName']);
      count += 1;
    }
    if (!formValues.lastName && !errors.includes('lastName')) {
      setErrors((prevState) => [...prevState, 'lastName']);
      count += 1;
    }
    if (!formValues.calendarUrl && !errors.includes('calendarUrl')) {
      setErrors((prevState) => [...prevState, 'calendarUrl']);
      count += 1;
    }
    if (
      formValues.stateOfResidence &&
      !formValues.stateOfResidence.label &&
      !errors.includes('stateOfResidence')
    ) {
      setErrors((prevState) => [...prevState, 'stateOfResidence']);
      count += 1;
    }
    if (!formValues.email && !errors.includes('email')) {
      setErrors((prevState) => [...prevState, 'email']);
      count += 1;
    }
    if (formValues.email && !validateEmail(formValues.email)) {
      setErrors((prevState) => [...prevState, 'invalidEmail']);
      count += 1;
    }
    return !!errors.length || !!count;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      aboutMe,
      firstName,
      lastName,
      email,
      stateOfResidence,
      calendarUrl,
      // introVideoUrl,
      // profileImageUrl,
      specialist,
      titleDegree,
      online,
      inhome,
    } = formValues;

    const anyError = await preSubmit();

    if (!anyError) {
      const formData: IFormData = {
        aboutMe,
        firstName,
        lastName,
        email,
        calendarUrl,
        // introVideoUrl,
        // profileImageUrl,
        specialist: specialist === 'yes',
        online: online === 'yes',
        inhome: inhome === 'yes',
        titleDegree,
        state: stateOfResidence ? stateOfResidence.value : 0,
      };

      if (editMode.id) {
        dispatch(sendTutorEditData(formData, editMode.id));
      } else {
        dispatch(sendTutorAdditionData(formData));
      }
    }
  };

  const locationsOptions: Array<IdbItem> = locations
    .map((e) => ({
      value: e.id,
      label: e.name,
    }))
    .sort((a, b) => a.value - b.value);

  const emailValidation = (errors: Array<string>) => {
    if (errors.includes('email')) {
      return 'Email is Required';
    }

    if (errors.includes('invalidEmail')) {
      return 'Invalid email';
    }

    if (errors.includes('emailAlreadyExist')) {
      return 'Email in use!';
    }

    return false;
  };

  const calendarValidation = (errors: Array<string>) => {
    if (errors.includes('calendarUrl')) {
      return 'Calendar Url is Required';
    }

    if (errors.includes('calendarAlreadyExist')) {
      return 'Calendar in use!';
    }

    return false;
  };

  const optionSelectValidate = (value, options) =>
    options.some((v) => _.isEqual(value, v)) ? value : false;

  return (
    <div className="container-size">
      <Title>{editMode.id ? 'Edit Tutor' : 'Create a new Tutor'}</Title>
      <Subtitle ref={myRef}>Tutor&apos;s info:</Subtitle>
      <form onSubmit={handleSubmit}>
        {showError && (
          <Error center>
            There is an error in one of the fields, please check it and try
            again.
          </Error>
        )}
        <FieldsBox>
          <Field
            label="First Name*"
            name="firstName"
            type="text"
            handleChange={handleChange}
            value={formValues.firstName}
            error={errors.includes('firstName') && 'First Name is Required'}
          />
          <Field
            label="Last Name*"
            name="lastName"
            type="text"
            handleChange={handleChange}
            value={formValues.lastName}
            error={errors.includes('lastName') && 'Last Name is Required'}
          />
        </FieldsBox>
        <FieldsBox>
          <Field
            label="Email Address*"
            name="email"
            type="text"
            handleChange={handleChange}
            value={formValues.email}
            error={emailValidation(errors)}
          />
          <Field
            label="Calendar Url*"
            name="calendarUrl"
            type="text"
            handleChange={handleChange}
            value={formValues.calendarUrl}
            error={calendarValidation(errors)}
          />
        </FieldsBox>
        <FieldsBox>
          <Select
            label="State of residence*"
            options={locationsOptions}
            name="stateOfResidence"
            value={optionSelectValidate(
              formValues.stateOfResidence,
              locationsOptions
            )}
            placeholder="Select"
            handleChange={handleChangeStateOfResidence}
            error={
              errors.includes('stateOfResidence') && 'Please select a state'
            }
            required
          />
          <Field
            label="Title degree"
            name="titleDegree"
            type="text"
            handleChange={handleChange}
            value={formValues.titleDegree}
          />
        </FieldsBox>
        <FieldBox>
          <br />
          <br />
          <CheckboxContainer>
            <Checkbox
              type="checkbox"
              name="online"
              value={formValues.online}
              checked={formValues.online === 'yes'}
              handleChange={handleChange}
              label="Online"
            />
            <Checkbox
              type="checkbox"
              name="inhome"
              value={formValues.inhome}
              checked={formValues.inhome === 'yes'}
              handleChange={handleChange}
              label="In-Home"
            />
          </CheckboxContainer>
        </FieldBox>
        <FieldBox full>
          <TextField
            label="About Tutor"
            full
            name="aboutMe"
            handleChange={handleChange}
            value={formValues.aboutMe}
          />
        </FieldBox>
        {showTutorAdded && (
          <TutorAddedTag>Tutor added successfully!</TutorAddedTag>
        )}
        {showTutorEdited && (
          <TutorAddedTag>Tutor edited successfully!</TutorAddedTag>
        )}

        <SubmitSection>
          <SubmitButtons>
            <Button type="submit">Submit</Button>
          </SubmitButtons>
        </SubmitSection>
      </form>
    </div>
  );
}
