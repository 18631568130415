import styled from 'styled-components';

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const SessionEvent = styled.div<{
  error?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 10px;
  padding: 16px 20px;
  cursor: pointer;
  ${({ theme, error }) =>
    `border: 1px solid ${
      error ? theme.colors.alert.default : theme.colors.quaternary.default
    };
    `}
  ${({ theme, error }) => error && `color: ${theme.colors.alert.default}`}
`;

export const SchedulerModal = styled.div`
  position: relative;
`;

export const CalendlyContainer = styled.div`
  position: relative;
  width: 800x;

  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const EventParticipants = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;

  div {
    display: flex;
    align-items: center;
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LFAvatar = styled.div`
  height: 48px;
  width: 48px;
  margin-right: 8px;
  img {
    width: 100%;
    border-radius: 100%;
  }
  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    width: 40px;
  }
`;

export const Error = styled.div`
  font-weight: 800;
  font-size: 1.2rem;
  ${({ theme }) => `color: ${theme.colors.alert.default}`}
`;
