import { capitalize } from 'lodash';

const getUpperLowerRow = (section, casing: 'upper' | 'lower') => [
  `${section.label} - ${capitalize(casing)}`,
  section[casing].total,
  '-',
  section[casing].correct,
  section[casing].incorrect,
  section[casing].skipped,
  section[casing].comments.join('. '),
  `${((section[casing].correct / section[casing].total) * 100).toFixed(2)} %`,
  section[casing].skipped === section[casing].total ? 'SKIPPED' : '',
];

const getRow = (section) => [
  section.label,
  section.scores.total,
  section.scores.practice ? section.scores.practice : '-',
  section.scores.correct,
  section.scores.incorrect,
  section.scores.skipped,
  section.scores.comments.join('. '),
  `${((section.scores.correct / section.scores.total) * 100).toFixed(2)} %`,
  section.scores.skipped === section.scores.total ? 'SKIPPED' : '',
];

const getReportRows = (sections, respondentId, collectorId) => {
  const reportRows = [
    [
      `Respondent - ${respondentId}   Collector - ${collectorId}`,
      'Total',
      'Practice',
      'Correct',
      'Incorrect',
      'Skipped',
      'Comments',
      '% Correct',
      'Section Skipped',
    ],
  ];

  sections.forEach((section) => {
    if (section.lower && section.upper) {
      const upperRow = getUpperLowerRow(section, 'upper');
      const lowerRow = getUpperLowerRow(section, 'lower');
      reportRows.push(upperRow);
      reportRows.push(lowerRow);
    } else {
      const row = getRow(section);
      reportRows.push(row);
    }
  });
  return reportRows;
};

export default getReportRows;
