import React, { useState, useEffect } from 'react';

import spreadsheetIcon from 'assets/icons/spreadsheet.svg';

import downloadReport from '../downloadReport';

import UploadComponent from '../UploadComponent';

import generateLiteracyScreenerReport from './generateLiteracyScreenerReport';

import * as styles from '../reports.module.scss';

export default function LiteracyScreenerReport() {
  const [responsesFile, setResponsesFile] = useState<File | null>(null);
  const [reportContent, setContent] = useState<ArrayBuffer | string>('');
  const [reportRows, setReportRows] = useState<string[][]>([]);
  const [respondentId, setRespondentId] = useState<string>('');

  useEffect(() => {
    if (!reportContent) return;
    const { reportRows, respondentId } =
      generateLiteracyScreenerReport(reportContent);
    setReportRows(reportRows || []);
    setRespondentId(respondentId || '');
  }, [reportContent]);

  const reset = () => {
    setResponsesFile(null);
    setReportRows([]);
    setContent('');
    setRespondentId('');
  };

  return (
    <div className={styles.reportContainer}>
      <div className={styles.row}>
        <div>
          <h2>K-5 Literacy Screener</h2>

          {responsesFile && (
            <div className={styles.reset} onClick={reset}>
              Reset
            </div>
          )}
        </div>

        <UploadComponent
          responsesFile={responsesFile}
          setResponsesFile={setResponsesFile}
          setContent={(content) => setContent(content)}
          contentPresent={!!reportContent}
          report="literacyScreener"
        />

        {reportRows.length > 0 && (
          <div className={styles.downloadRow}>
            <div className={styles.fileCard}>
              <img src={spreadsheetIcon} alt="file icon" />
              <strong>literacy_screener_{respondentId}.csv</strong>
              <div>{new Date().toLocaleString()}</div>
            </div>
            <div
              className={styles.downloadButton}
              onClick={() =>
                downloadReport('literacyScreener', reportRows, respondentId)
              }
            >
              Download Report
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
