import moment from 'moment';

const fileNames = {
  parentScreener: 'parent_screener',
  literacyScreener: 'literacy_screener',
};
const downloadReport = (
  reportName: string,
  reportRows,
  responderId: string
) => {
  const rows = reportRows.map((r) => r.join(',')).join('\n');
  const csvContent = `data:text/csv;charset=utf-8,${rows}`;

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  const now = new Date();
  const timestamp = moment(now).format('h-mma__M-DD');
  const fileName = fileNames[reportName];
  link.setAttribute(
    'download',
    `${fileName}_${responderId}___${timestamp}.csv`
  );
  document.body.appendChild(link); // Required for FF
  link.click();
};

export default downloadReport;
