const wmConversion = {
  55: {
    rank: 99,
    score: 134,
    class: 'very superior',
  },
  54: {
    rank: 98,
    score: 132,
    class: 'very superior',
  },
  53: {
    rank: 98,
    score: 130,
    class: 'very superior',
  },
  52: {
    rank: 97,
    score: 129,
    class: 'superior',
  },
  51: {
    rank: 96,
    score: 127,
    class: 'superior',
  },
  50: {
    rank: 95,
    score: 125,
    class: 'superior',
  },
  49: {
    rank: 95,
    score: 124,
    class: 'superior',
  },
  48: {
    rank: 94,
    score: 123,
    class: 'superior',
  },
  47: {
    rank: 91,
    score: 120,
    class: 'superior',
  },
  46: {
    rank: 88,
    score: 118,
    class: 'high average',
  },
  45: {
    rank: 86,
    score: 116,
    class: 'high average',
  },
  44: {
    rank: 82,
    score: 114,
    class: 'high average',
  },
  43: {
    rank: 81,
    score: 113,
    class: 'high average',
  },
  42: {
    rank: 77,
    score: 111,
    class: 'high average',
  },
  41: {
    rank: 75,
    score: 110,
    class: 'high average',
  },
  40: {
    rank: 70,
    score: 108,
    class: 'average',
  },
  39: {
    rank: 66,
    score: 106,
    class: 'average',
  },
  38: {
    rank: 63,
    score: 105,
    class: 'average',
  },
  37: {
    rank: 58,
    score: 103,
    class: 'average',
  },
  36: {
    rank: 53,
    score: 101,
    class: 'average',
  },
  35: {
    rank: 47,
    score: 99,
    class: 'average',
  },
  34: {
    rank: 42,
    score: 97,
    class: 'average',
  },
  33: {
    rank: 37,
    score: 95,
    class: 'average',
  },
  32: {
    rank: 34,
    score: 94,
    class: 'average',
  },
  31: {
    rank: 30,
    score: 92,
    class: 'average',
  },
  30: {
    rank: 25,
    score: 90,
    class: 'average',
  },
  29: {
    rank: 21,
    score: 88,
    class: 'low average',
  },
  28: {
    rank: 18,
    score: 86,
    class: 'low average',
  },
  27: {
    rank: 14,
    score: 84,
    class: 'low average',
  },
  26: {
    rank: 12,
    score: 82,
    class: 'low average',
  },
  25: {
    rank: 10,
    score: 81,
    class: 'low average',
  },
  24: {
    rank: 8,
    score: 79,
    class: 'below average',
  },
  23: {
    rank: 6,
    score: 77,
    class: 'below average',
  },
  22: {
    rank: 5,
    score: 75,
    class: 'below average',
  },
  21: {
    rank: 4,
    score: 74,
    class: 'below average',
  },
  20: {
    rank: 3,
    score: 72,
    class: 'below average',
  },
  19: {
    rank: 2,
    score: 70,
    class: 'below average',
  },
  18: {
    rank: 2,
    score: 68,
    class: 'well below average',
  },
  17: {
    rank: 1,
    score: 66,
    class: 'well below average',
  },
  16: {
    rank: 1,
    score: 65,
    class: 'well below average',
  },
  15: {
    rank: 1,
    score: 63,
    class: 'well below average',
  },
  14: {
    rank: 1,
    score: 62,
    class: 'well below average',
  },
  13: {
    rank: 1,
    score: 59,
    class: 'well below average',
  },
  12: {
    rank: 1,
    score: 58,
    class: 'well below average',
  },
  11: {
    rank: 1,
    score: 56,
    class: 'well below average',
  },
  10: {
    rank: 1,
    score: 55,
    class: 'well below average',
  },
  9: {
    rank: 1,
    score: 52,
    class: 'well below average',
  },
  8: {
    rank: 1,
    score: 51,
    class: 'well below average',
  },
  7: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  6: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  5: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  4: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  3: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  2: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  1: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  0: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
};

export default wmConversion;
