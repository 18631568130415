import styled from 'styled-components';

export const SessionsWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0;

  h2 {
    width: 100%;
  }
`;

export const SessionTile = styled.div`
  box-sizing: border-box;
  border: 1px solid rgba(100, 100, 100, 0.4);
  border-radius: 0.7rem;
  width: 12rem;
  height: 10rem;
  padding: 1rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
`;

export const SessionName = styled.div`
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

export const SessionTutor = styled.div`
  color: rgba(100, 100, 100, 0.7);
  margin-bottom: 1rem;
`;

export const SessionDate = styled.div`
  display: flex;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;

  svg {
    margin-right: 0.3rem;
  }
`;
