import { Scores } from './literacy.interfaces';

import cleanComment from './cleanComment';

const getLowerUpperScores = (section, questions, answers) => {
  const lowerScores: Scores = {
    total: 0,
    correct: 0,
    incorrect: 0,
    skipped: 0,
    comments: [],
  };

  const upperScores: Scores = {
    total: 0,
    correct: 0,
    incorrect: 0,
    skipped: 0,
    comments: [],
  };

  for (let i = section.startIndex; i <= section.endIndex; i += 1) {
    const question = questions[i];
    if (question.toLowerCase().includes('other')) {
      const prevQuestion = questions[i - 1];
      const scores =
        prevQuestion === prevQuestion.toLowerCase() ? lowerScores : upperScores;
      const response = answers[i];
      if (response && response !== '\r')
        scores.comments.push(cleanComment(response));
      continue;
    }

    const scores =
      question === question.toLowerCase() ? lowerScores : upperScores;
    const answer = answers[i];

    scores.total += 1;
    if (answer === '1') scores.correct += 1;
    else if (answer === '0') scores.incorrect += 1;
    else scores.skipped += 1;
  }

  return [lowerScores, upperScores];
};

export default getLowerUpperScores;
