const fxConversion = {
  35: {
    rank: 99,
    score: 139,
    class: 'very superior',
  },
  34: {
    rank: 99,
    score: 141,
    class: 'very superior',
  },
  33: {
    rank: 99,
    score: 138,
    class: 'very superior',
  },
  32: {
    rank: 99,
    score: 135,
    class: 'very superior',
  },
  31: {
    rank: 98,
    score: 132,
    class: 'very superior',
  },
  30: {
    rank: 98,
    score: 130,
    class: 'very superior',
  },
  29: {
    rank: 96,
    score: 127,
    class: 'superior',
  },
  28: {
    rank: 95,
    score: 124,
    class: 'superior',
  },
  27: {
    rank: 92,
    score: 121,
    class: 'superior',
  },
  26: {
    rank: 88,
    score: 118,
    class: 'high average',
  },
  25: {
    rank: 84,
    score: 115,
    class: 'high average',
  },
  24: {
    rank: 79,
    score: 112,
    class: 'high average',
  },
  23: {
    rank: 73,
    score: 109,
    class: 'average',
  },
  22: {
    rank: 66,
    score: 106,
    class: 'average',
  },
  21: {
    rank: 58,
    score: 103,
    class: 'average',
  },
  20: {
    rank: 50,
    score: 100,
    class: 'average',
  },
  19: {
    rank: 45,
    score: 98,
    class: 'average',
  },
  18: {
    rank: 37,
    score: 95,
    class: 'average',
  },
  17: {
    rank: 30,
    score: 92,
    class: 'average',
  },
  16: {
    rank: 23,
    score: 89,
    class: 'low average',
  },
  15: {
    rank: 18,
    score: 86,
    class: 'low average',
  },
  14: {
    rank: 13,
    score: 83,
    class: 'low average',
  },
  13: {
    rank: 9,
    score: 80,
    class: 'low average',
  },
  12: {
    rank: 6,
    score: 77,
    class: 'below average',
  },
  11: {
    rank: 4,
    score: 74,
    class: 'below average',
  },
  10: {
    rank: 3,
    score: 72,
    class: 'below average',
  },
  9: {
    rank: 2,
    score: 69,
    class: 'well below average',
  },
  8: {
    rank: 1,
    score: 66,
    class: 'well below average',
  },
  7: {
    rank: 1,
    score: 63,
    class: 'well below average',
  },
  6: {
    rank: 1,
    score: 60,
    class: 'well below average',
  },
  5: {
    rank: 1,
    score: 57,
    class: 'well below average',
  },
  4: {
    rank: 1,
    score: 54,
    class: 'well below average',
  },
  3: {
    rank: 1,
    score: 51,
    class: 'well below average',
  },
  2: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  1: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
  0: {
    rank: 1,
    score: 50,
    class: 'well below average',
  },
};

export default fxConversion;
