import readResponses from './readResponses';
import getSections from './getSections';
import getReportRows from './getReportRows';

export default function generateLiteracyScreenerReport(
  reportContent: string | ArrayBuffer
) {
  const { responses, respondentId, collectorId } = readResponses(reportContent);
  if (!responses) {
    console.error('ERROR - responses not detected');
    return {};
  }

  const sections = getSections(responses);
  const reportRows = getReportRows(sections, respondentId, collectorId);
  return { reportRows, respondentId };
}
