import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Wrapper, WrapperSuccess, IconWrapper } from './styles';
import question from '../../../assets/icons/Question.svg';
import check from '../../../assets/icons/Check.svg';
import { Field } from '../../../components/Form';
import Button from '../../../components/Button';
import { forgotPasswordSendEmail, clearData } from './forgot.action';
import IState from '../../../reducers';

export default function ForgotPassword() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearData());
  }, [dispatch]);
  const [email, setEmail] = useState<string>('');
  const forgotPassword = useSelector((state: IState) => state.forgotPassword);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      const payload = { email };
      dispatch(forgotPasswordSendEmail(payload));
    }
  };
  return (
    <>
      {forgotPassword?.success ? (
        <WrapperSuccess>
          <IconWrapper>
            <img src={check} alt="Question" />
          </IconWrapper>
          <h2>Check your email</h2>
          <p>
            Please check the email address <strong>{email}</strong> for
            instructions to reset your password.
          </p>
        </WrapperSuccess>
      ) : (
        <Wrapper>
          <IconWrapper>
            <img src={question} alt="Success" />
          </IconWrapper>
          <h2>Forgot your password?</h2>
          <p>Confirm your email and we’ll send you the instructions.</p>
          <form onSubmit={handleSubmit}>
            <Field
              placeholder="Type your email"
              label=""
              name="user"
              type="text"
              handleChange={handleChange}
              value={email}
              full
            />
            <Button type="submit">
              {forgotPassword?.loading ? 'Loading...' : 'Submit'}
            </Button>
          </form>
        </Wrapper>
      )}
    </>
  );
}
