/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export const GET_TUTORS_SUCCESS = 'GET_TUTORS_SUCCESS';
export const GET_RECOMMENDED_TUTORS_SUCCESS = 'GET_RECOMMENDED_TUTORS_SUCCESS';
export const RECOMMENDED_TUTOR_ADDED = 'RECOMMENDED_TUTOR_ADDED';
export const RECOMMENDED_TUTOR_FAILED = 'RECOMMENDED_TUTOR_FAILED';
export const RESET_RECOMMENDED_TUTOR_ADDED = 'RESET_RECOMMENDED_TUTOR_ADDED';
export const RECOMMENDED_TUTOR_DELETED = 'RECOMMENDED_TUTOR_DELETED';
export const RESET_RECOMMENDED_TUTOR_DELETED =
  'RESET_RECOMMENDED_TUTOR_DELETED';
const SEND_GET_TUTORS = 'SEND_GET_TUTORS';
const SEND_GET_RECOMMENDED_TUTORS = 'SEND_GET_RECOMMENDED_TUTORS';
const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/`;

export const sendGetTutors = () => ({
  type: SEND_GET_TUTORS,
});

export const getTutorsSuccess = (tutors) => ({
  type: GET_TUTORS_SUCCESS,
  tutors,
});

export const sendGetRecommendedTutors = () => ({
  type: SEND_GET_RECOMMENDED_TUTORS,
});

export const getRecommendedTutorsSuccess = (recommendedTutors) => ({
  type: GET_RECOMMENDED_TUTORS_SUCCESS,
  recommendedTutors,
});

export const recommendedTutorAdded = () => ({
  type: RECOMMENDED_TUTOR_ADDED,
});
export const recommendedTutorFailed = (err) => ({
  type: RECOMMENDED_TUTOR_FAILED,
  err,
});

export const resetRecommendedTutorAdded = () => ({
  type: RESET_RECOMMENDED_TUTOR_ADDED,
});

export const resetRecommendedTutorDeleted = () => ({
  type: RESET_RECOMMENDED_TUTOR_DELETED,
});

export const recommendedTutorDeleted = () => ({
  type: RECOMMENDED_TUTOR_DELETED,
});

export const getTutors = (searchOptions?) => (dispatch) => {
  const token = localStorage.getItem('token');
  dispatch(sendGetTutors());
  let parameters = '';
  if (searchOptions && searchOptions.filter?.searchInput) {
    parameters +=
      searchOptions.filter.radioFilter &&
      `&${searchOptions.filter.radioFilter}=${searchOptions.filter.searchInput}`;
  }
  if (searchOptions?.sort?.accessor) {
    parameters += `&sort=${searchOptions.sort.accessor},${searchOptions.sort.order}`;
  }
  if (searchOptions && searchOptions.page) {
    parameters += `&page=${searchOptions.page}`;
  }
  if (!searchOptions) parameters += `&page=0`;

  const size = searchOptions?.size || 10;

  axios
    .get(
      `${HOST_PROFILE}admin/tutor?&size=${size}${parameters && parameters}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      dispatch(getTutorsSuccess(res.data));
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.error('getTutor Error');
      // dispatch(getLearnersSuccess(null));
    });
};

export const getRecommendedTutors = (learnerId) => (dispatch) => {
  dispatch(sendGetRecommendedTutors());
  const token = localStorage.getItem('token');

  axios
    .get(`${HOST_PROFILE}recommendation/learner/${learnerId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch(getRecommendedTutorsSuccess(res.data));
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.error('getrecommended Tutor Error');
      // dispatch(getLearnersSuccess(null));
    });
};

export const updateRecommendedTutorSession =
  (recommendedTutorData) => (dispatch) => {
    const token = localStorage.getItem('token');

    axios
      .patch(`${HOST_PROFILE}admin/recommendation`, recommendedTutorData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(getRecommendedTutors(recommendedTutorData.learnerId));
      });
  };

export const addRecommendedTutor =
  (tutorId, learnerId, online = false) =>
  (dispatch) => {
    const token = localStorage.getItem('token');
    const data = { tutorId, learnerId, online };
    axios
      .post(`${HOST_PROFILE}/admin/recommendation`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        dispatch(recommendedTutorAdded());
      })
      .catch((err) => {
        dispatch(recommendedTutorFailed(err.response.data.errorMessage));
      });
  };

export const deleteRecommendedTutor = (learnerId, tutorId) => (dispatch) => {
  const token = localStorage.getItem('token');
  axios
    .delete(
      `${HOST_PROFILE}/admin/recommendation/learner/${learnerId}/tutor/${tutorId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(() => {
      dispatch(recommendedTutorDeleted());
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
};
