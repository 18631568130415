import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '../../helpers/useAuthentication';
import { sendLoginData, loginFailure } from './signIn.action';
import { Field, Error } from '../../components/Form';
import {
  Main,
  FormContainer,
  ContainerPassword,
  SubmitContainer,
  Background,
  Container,
  ForgotButton,
  ContainerError,
} from './styles';
import Button from '../../components/Button';
import { Modal } from '../../components';
import ForgotPassword from './ForgotPassword';
import IState from '../../reducers';

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const loginInfo = useSelector((state: IState) => state.loginInfo);
  const isAuthenticated = useIsAuthenticated();
  const [data, setData] = useState({
    user: '',
    password: '',
  });
  const [attempts, setAttempts] = useState<number>(0);
  const [inputType, setInputType] = useState<string>('password');
  const [showForgotModal, setShowForgotModal] = useState<boolean>(false);
  const { from } = location.state || { from: { pathname: '/home' } };
  const { isMobile } = useSelector((state: IState) => state.devices);

  useEffect(() => {
    if (loginInfo.loginFailure) {
      setAttempts((prevAttemps) => prevAttemps + 1);
    }
  }, [loginInfo.loginFailure]);

  const attemptsError = attempts >= 3;

  useEffect(() => {
    if (loginInfo.logged && isAuthenticated) history.replace(from);
  }, [loginInfo, history, isAuthenticated, from]);

  useEffect(() => {
    if (loginInfo.loginFailure !== '')
      setTimeout(() => dispatch(loginFailure('')), 5000);
  }, [dispatch, loginInfo.loginFailure]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.user && data.password) {
      const loginData = {
        user: data.user.trim(),
        password: data.password.trim(),
      };
      dispatch(sendLoginData(loginData));
    }
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleVisible = () =>
    setInputType(inputType === 'password' ? 'text' : 'password');

  return (
    <Main className="container-size">
      <Background />
      <Container>
        <h1>Sign in</h1>
        <p>Admin portal</p>
        <FormContainer>
          <form onSubmit={handleSubmit}>
            <Field
              label="User"
              name="user"
              type="text"
              handleChange={handleChange}
              value={data.user}
              placeholder=""
              full
            />

            <ContainerPassword>
              <Field
                label="Password"
                name="password"
                type={inputType}
                autoComplete="off"
                handleChange={handleChange}
                value={data.password}
                full
              />

              <FontAwesomeIcon
                icon={inputType === 'text' ? faEye : faEyeSlash}
                onClick={handleVisible}
              />
            </ContainerPassword>

            <SubmitContainer>
              <Button type="submit">
                {loginInfo.loading ? 'Loading...' : 'Sign in'}
              </Button>
              {loginInfo.loginFailure && !attemptsError && (
                <ContainerError>
                  <Error>{loginInfo.loginFailure}</Error>
                </ContainerError>
              )}
              {attemptsError && (
                <Error>
                  If you forgot your password you can recover it using your
                  email{' '}
                  <strong
                    className="cursor-pointer"
                    onClick={() => setShowForgotModal(true)}
                  >
                    here.
                  </strong>
                </Error>
              )}
            </SubmitContainer>
          </form>

          <ForgotButton onClick={() => setShowForgotModal(true)} type="button">
            Forgot password?
          </ForgotButton>

          <Modal
            mw={479}
            isOpen={showForgotModal}
            full={isMobile}
            closeModal={() => setShowForgotModal(false)}
          >
            <ForgotPassword />
          </Modal>
        </FormContainer>
      </Container>
    </Main>
  );
}
