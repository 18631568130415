import axios from 'axios';

export const GET_LEARNERS_SUCCESS = 'GET_LEARNERS_SUCCESS';
const SEND_GET_LEARNERS = 'SEND_GET_LEARNERS';
export const SCHEDULE_SESSION = 'SCHEDULE_SESSION';
export const SCHEDULE_SESSION_FAILURE = 'SCHEDULE_SESSION_FAILURE';
export const SCHEDULE_SESSION_SUCCESS = 'SCHEDULE_SESSION_SUCCESS';
export const CLEAR_SESSIONS = 'CLEAR_SESSIONS';
export const CLEAR_SUCCESS_MESSAGE = 'CLEAR_SUCCESS_MESSAGE';
export const SET_ACTUAL_LEARNER = 'SET_ACTUAL_LEARNER';
export const GET_ACTIVATION_LINK = 'GET_ACTIVATION_LINK';

const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/`;
const HOST_TUTORING = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/tutoring/api/v1/`;

export const sendGetLearners = () => ({
  type: SEND_GET_LEARNERS,
});

export const getLearnersSuccess = (learners) => ({
  type: GET_LEARNERS_SUCCESS,
  learners,
});

export const getActivationLink = (activationLink) => ({
  type: GET_ACTIVATION_LINK,
  activationLink,
});

export const getClearSuccessMessage = () => ({
  type: CLEAR_SUCCESS_MESSAGE,
});

const sessionScheduled = () => ({
  type: SCHEDULE_SESSION_SUCCESS,
});

export const clearSessions = () => (dispatch) => {
  dispatch({ type: CLEAR_SESSIONS });
};

export const handleActualLearner = (learner) => ({
  type: SET_ACTUAL_LEARNER,
  learner,
});

export const clearSuccessMessage = () => (dispatch) => {
  dispatch(getClearSuccessMessage());
};

export const scheduleSessionFailure = (errMessage) => (dispatch) => {
  dispatch({
    type: SCHEDULE_SESSION_FAILURE,
    errMessage,
  });
};

export const getLearners = (searchOptions?) => (dispatch) => {
  const token = localStorage.getItem('token');
  dispatch(sendGetLearners());
  let parameters = '';
  if (searchOptions && searchOptions.filter.searchInput) {
    parameters +=
      searchOptions.filter.radioFilter &&
      `&${searchOptions.filter.radioFilter}=${encodeURIComponent(
        searchOptions.filter.searchInput
      )}`;
  }
  if (searchOptions?.isActive === 'false') {
    parameters += '&isUserActive=false';
  }
  if (searchOptions?.isActive === 'true') {
    parameters += '&isUserActive=true';
  }
  if (searchOptions?.sort?.accessor) {
    parameters += `&sort=${searchOptions.sort.accessor},${searchOptions.sort.order}`;
  }
  if (searchOptions && searchOptions.page) {
    parameters += `&page=${searchOptions.page}`;
  }
  if (!searchOptions) parameters += `&page=0`;

  axios
    .get(
      `${HOST_PROFILE}admin/user/learner?size=20${parameters && parameters}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      dispatch(getLearnersSuccess(res.data));
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.error('getUser Error');
      // dispatch(getLearnersSuccess(null));
    });
};

export const scheduleSession = (sessionData) => (dispatch) => {
  axios
    .post(`${HOST_TUTORING}/admin/package/session`, sessionData, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    .then(() => {
      dispatch(sessionScheduled());
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      dispatch(scheduleSessionFailure(err.response.data.errorMessage));
    });
};

export const setActualLearner = (learner) => (dispatch) => {
  dispatch(handleActualLearner(learner));
};

export const generateActivationLink = (userId) => (dispatch) => {
  axios
    .get(`${HOST_PROFILE}admin/user/${userId}/activationLink`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    .then((res) => {
      dispatch(getActivationLink(res.data));
    });
};
