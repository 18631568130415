const categoryQuestions = {
  at: [3, 11, 21, 25, 36, 44, 56, 62, 75, 80, 91, 97],
  er: [10, 12, 42, 47, 64, 68, 73, 79, 81],
  fx: [7, 41, 45, 60, 67, 88, 99],
  ic: [1, 19, 32, 38, 49, 70, 74, 92, 96, 98],
  it: [16, 30, 39, 40, 55, 58, 65, 78, 84, 93],
  og: [5, 13, 18, 27, 34, 52, 63, 76, 83, 89],
  pl: [9, 15, 22, 28, 35, 50, 59, 71, 86, 90, 100],
  sm: [6, 14, 17, 29, 37, 48, 53, 69, 82, 94],
  wm: [4, 8, 23, 26, 31, 43, 51, 57, 72, 85, 87],
};

const checkCategoryQuestions = () => {
  const doubles: Array<number> = [];
  const unseen: Array<number> = [];

  for (let i = 0; i < 100; i += 1) {
    let seen = false;
    const qn = i + 1;
    Object.keys(categoryQuestions).forEach((k) => {
      const present = categoryQuestions[k].includes(qn);
      if (present && seen) doubles.push(qn);
      if (present) seen = true;
    });

    if (!seen) unseen.push(qn);
  }
};

const getCategoryLabel = (qn: number) => {
  let label = '';
  const categoryLabels = Object.keys(categoryQuestions);
  for (let i = 0; i < categoryLabels.length; i += 1) {
    const category = categoryLabels[i];
    const present = categoryQuestions[category].includes(qn);
    if (present) {
      label = category;
      break;
    }
  }

  if (!label) return 'none';
  return label;
};

interface CategorySums {
  [key: string]: number;
}
const getSums = (
  responses: Array<Array<string | false | null>>
): { categorySums: CategorySums; excludedQuestions: number[] } => {
  checkCategoryQuestions();

  const excludedQuestions: number[] = [];

  const categorySums = {
    at: 0,
    er: 0,
    fx: 0,
    ic: 0,
    it: 0,
    og: 0,
    pl: 0,
    sm: 0,
    wm: 0,
  };
  responses.forEach((res, idx: number) => {
    const qn = idx + 1;
    const label = getCategoryLabel(qn);
    if (label === 'none') excludedQuestions.push(qn);
    else if (label && res[1]) categorySums[label] += parseInt(res[1]);
  });

  return { categorySums, excludedQuestions };
};

export default getSums;
