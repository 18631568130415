import {
  SCHEDULE_SESSION_SUCCESS,
  CLEAR_SESSIONS,
  CLEAR_SUCCESS_MESSAGE,
  SCHEDULE_SESSION_FAILURE,
} from '../pages/Home/home.action';

const initialState = {
  sessionScheduled: false,
  sessionIndex: 0,
  errorMessage: '',
};

export default function sessionsSchedule(state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_SESSION_SUCCESS:
      return {
        ...state,
        sessionScheduled: true,
        errorMessage: '',
      };
    case SCHEDULE_SESSION_FAILURE:
      return {
        ...state,
        errorMessage: action.errMessage,
      };
    case CLEAR_SESSIONS:
      return {
        ...state,
        sessionScheduled: false,
        errorMessage: '',
      };
    case CLEAR_SUCCESS_MESSAGE:
      return {
        ...state,
        sessionScheduled: false,
      };
    default:
      return state;
  }
}
