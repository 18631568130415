export interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  state: number;
  learners: {
    alias: string;
    grade: number;
    interestsTags: number[];
    otherInterests: string[];
  }[];
  accessToMandatoryTech;
  termsAndConditions: boolean;
  phoneNumber?: string;
}

export interface IdbItem {
  value: number;
  label: string;
}

export interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
  stateOfResidence: { value: number; label: string };
  phone: string;
  accessToMandatoryTech: boolean;
  terms: boolean;
  learnersGroup: {
    [key: number]: {
      learnerIndex: number;
      firstName: string;
      grade: { value: number; label: string };
      interested: number[];
      other: string[];
      bundle: number;
    };
  };
}

export const initialState = {
  locations: [],
  servicesInterestedIn: [],
  grades: [],
  lastLearnerCreated: 0,
  errors: [],
  showError: false,
  formValues: {
    firstName: '',
    lastName: '',
    email: '',
    stateOfResidence: { value: 0, label: '' },
    phone: '',
    learnersGroup: {
      0: {
        firstName: '',
        grade: { value: 0, label: '' },
        interested: [],
        learnerIndex: 0,
        other: [],
        bundle: 0,
      },
    },
    accessToMandatoryTech: false,
    terms: false,
  },
};
