const cutoffTerms = [
  `Now I'm`,
  'E (VCE)',
  'GRADES (1-5)',
  'leNow',
  '-le',
  'DIRECTIONS',
  'SAY',
  'PRACTICE',
];

const replaceTerms = {
  DECODINGSECTION: 'DECODING SECTION',
};

const translateOldLabels = (oldLabel: string) => {
  let newLabel = oldLabel;

  Object.keys(replaceTerms).forEach((ot) => {
    const updatedLabel = newLabel.replace(ot, replaceTerms[ot]);
    newLabel = updatedLabel;
  });

  cutoffTerms.forEach((term) => {
    const cutoffIndex = newLabel.indexOf(term);
    if (cutoffIndex > 0) newLabel = newLabel.slice(0, cutoffIndex);
  });

  return newLabel;
};

export default translateOldLabels;
