import readResponses from './readResponses';
import getSums from './getSums';
import getResults from './getResults';
import getReportRows from './getReportRows';

export default function generateParentScreenerReport(
  content: string | ArrayBuffer
) {
  const { responses, ageGroup, error, responderId } = readResponses(content);
  if (error) console.error(error);
  if (!responses || !ageGroup || !responderId) return {};

  const { categorySums, excludedQuestions } = getSums(responses);
  const results = getResults(categorySums, ageGroup);

  const reportRows = getReportRows({ responses, categorySums, results });
  const ageLabel: string = ['5-11 years', '12-18 years'][ageGroup - 1];
  return { reportRows, excludedQuestions, results, ageLabel, responderId };
}
