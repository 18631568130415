import axios from 'axios';

import { IsExpired } from 'helpers/timeHelper';

import { logout } from 'commonActions';

import { getLearnerSessions } from '../learner.action';

const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/`;
const HOST_TUTORING = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/tutoring/api/v1/`;

export const getRecommendedTutors = (learnerId: string) => {
  const token = localStorage.getItem('token');
  axios
    .get(`${HOST_PROFILE}recommendation/learner/${learnerId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      // console.info(res);
    })
    .catch(() => {
      console.error('getrecommended Tutor Error');
    });
};

export const getTutorSessionsDuration = (tutorEmail: string) => {
  const token = localStorage.getItem('token');
  return axios
    .get(`${HOST_TUTORING}tutor/calendarEvents?tutorEmail=${tutorEmail}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => res.data.events)
    .catch((err) => {
      console.error(err);
    });
};

export const saveScheduledEventDynamic = (calendlyObj) => (dispatch) => {
  const token = localStorage.getItem('token');
  if (IsExpired(token)) {
    dispatch(logout);
    localStorage.removeItem('token');
    localStorage.clear();
  } else {
    axios
      .post(`${HOST_TUTORING}package/session`, calendlyObj, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        dispatch(getLearnerSessions(calendlyObj.learnerId));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        // dispatch(saveScheduledEventFailure());
      });
  }
};
