import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

export const FieldsBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

interface IFieldBox {
  full?: boolean;
  width?: number;
}

export const FieldBox = styled.div<IFieldBox>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  max-width: ${({ full }) => (full ? '100%' : '49%')};
  margin-bottom: 24px;

  @media only screen and (max-width: 680px) {
    max-width: 100%;
  }

  svg {
    height: 32px;
    width: 32px;
  }
`;

export const TextAreaBox = styled.div<IFieldBox>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  max-width: '100%';
  margin-bottom: 24px;

  svg {
    height: 32px;
    width: 32px;
  }
`;

export const FieldsFluid = styled.div<{ width?: number; relative?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ width }) => `width: ${width ? `${width}px` : '100%'}`};
  @media only screen and (max-width: 680px) {
    width: 100%;
  }
  ${({ relative }) => relative && `position: relative`}
`;

const LabelStyle = styled.label<{ width?: number }>`
  margin-bottom: 8px;
  ${({ width }) => width && `width: ${width}px`}
`;
interface LabelProps {
  name: string;
  label: string;
  width?: number;
}
export const Label = ({ name, label, width }: LabelProps) => (
  <LabelStyle htmlFor={name} width={width}>
    <b>{parse(label)}</b>
  </LabelStyle>
);

interface IInput {
  error?: boolean;
  errColor?: boolean;
  dirty?: boolean;
  white?: boolean;
}

interface ITextArea {
  error?: boolean;
  errColor?: boolean;
  dirty?: boolean;
}

export const Input = styled.input<IInput>`
  background: ${({ theme, white }) =>
    white ? theme.colors.white.default : theme.colors.quaternary.default};
  ${({ theme, errColor }) =>
    `color: ${
      errColor ? theme.colors.alert.default : theme.colors.main.default
    }`};
  ${({ width }) => width && `width: ${width}px`};
  border-radius: 8px;
  padding: 0 15px;
  border: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  height: 54px;
  line-height: 23.8px;
  transition: all 0.25s linear;
  box-sizing: border-box;

  ${({ error, theme }) =>
    error &&
    `
    border: 1px solid ${theme.colors.alert.default};
    background: ${theme.colors.white.default};
  `}

  &:active,
  &:focus {
    background: ${({ theme }) => theme.colors.white.default};
    border: 1px solid ${({ theme }) => theme.colors.main.default};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.tertiary.default};
    border: 0;
  }

  ${({ dirty, theme }) =>
    dirty &&
    `border: 1px solid ${theme.colors.main.default};
    background: ${theme.colors.white.default};`};
`;

export const TextArea = styled.textarea<ITextArea>`
  background: ${({ theme }) => theme.colors.quaternary.default};
  ${({ theme, errColor }) =>
    `color: ${
      errColor ? theme.colors.alert.default : theme.colors.main.default
    }`};
  border-radius: 8px;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  height: 100px;
  width: 100%;
  line-height: 23.8px;
  transition: all 0.25s linear;
  box-sizing: border-box;

  ${({ error, theme }) =>
    error &&
    `
    border: 1px solid ${theme.colors.alert.default};
    background: ${theme.colors.white.default};
  `}

  &:active,
  &:focus {
    background: ${({ theme }) => theme.colors.white.default};
    border: 1px solid ${({ theme }) => theme.colors.main.default};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.tertiary.default};
    border: 0;
  }

  ${({ dirty, theme }) =>
    dirty &&
    `border: 1px solid ${theme.colors.main.default};
    background: ${theme.colors.white.default};`};
`;

export const Error = styled.div<{ center?: boolean }>`
  padding: 10px 0;
  width: 100%;
  color: ${({ theme }) => theme.colors.alert.default};
  ${({ center }) => center && `text-align: center;`}
`;

export const IconAbsolute = styled.div<{
  errColor?: boolean;
  successColor?: boolean;
}>`
  position: absolute;
  right: 12px;
  top: 19px;
  ${({ theme, errColor }) => errColor && `color: ${theme.colors.alert.default}`}
  ${({ theme, successColor }) =>
    successColor && `color: ${theme.colors.success.default}`}
`;
