import React, { useState } from 'react';

import { Spinner } from 'components';

import spreadsheetIcon from 'assets/icons/spreadsheet.svg';

import * as styles from './reports.module.scss';

interface Props {
  responsesFile: File | null;
  setResponsesFile: (file: File) => void;
  setContent: (content: ArrayBuffer | string) => void;
  contentPresent: boolean;
  report: 'parentScreener' | 'literacyScreener';
}

const UploadComponent = ({
  responsesFile,
  setResponsesFile,
  setContent,
  contentPresent,
  report,
}: Props) => {
  const [generating, setGenerating] = useState(false);

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget?.files) return console.error('No file dected');

    const file = e.currentTarget.files[0];

    return setResponsesFile(file);
  };

  const generateReport = () => {
    if (!responsesFile) return console.error('file not present');
    setGenerating(true);

    const reader: FileReader = new FileReader();
    reader.onload = () => {
      if (reader.result === null)
        return console.error('could not read responses');
      const content = reader.result;

      setTimeout(() => {
        setGenerating(false);
        setContent(content);
      }, 500 + Math.random() * 500);

      return true;
    };
    reader.readAsText(responsesFile);
    return true;
  };

  const inputId = `${report}Upload`;

  return responsesFile ? (
    <div className={styles.row} style={{ marginLeft: '10rem' }}>
      <div className={styles.fileCard}>
        <img src={spreadsheetIcon} alt="file icon" />
        <strong>{responsesFile.name}</strong>
        <div>{new Date(responsesFile.lastModified).toLocaleString()}</div>
      </div>

      {generating && (
        <div style={{ marginLeft: '5rem' }}>
          <Spinner />
        </div>
      )}

      {!contentPresent && !generating && (
        <div className={styles.generateButton} onClick={generateReport}>
          Generate Report
        </div>
      )}
    </div>
  ) : (
    <div className={styles.row} style={{ marginLeft: 'auto' }}>
      <label htmlFor={inputId} className={styles.uploadButton}>
        Upload responses csv
      </label>
      <input
        type="file"
        id={inputId}
        className={styles.uploadInput}
        onChange={uploadFile}
      />
    </div>
  );
};

export default UploadComponent;
