import { CLEAN_USER, SET_USER } from '../commonActions';

const initialState = {
  name: '',
};

export default function userSettings(state = initialState, action) {
  switch (action.type) {
    case CLEAN_USER:
      return initialState;
    case SET_USER:
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
}
