import atConversion from './5-11-age-range/atConversion';
import erConversion from './5-11-age-range/erConversion';
import fxConversion from './5-11-age-range/fxConversion';
import icConversion from './5-11-age-range/icConversion';
import itConversion from './5-11-age-range/itConversion';
import ogConversion from './5-11-age-range/ogConversion';
import plConversion from './5-11-age-range/plConversion';
import smConversion from './5-11-age-range/smConversion';
import wmConversion from './5-11-age-range/wmConversion';

import atConversion1218 from './12-18-age-range/atConversion';
import erConversion1218 from './12-18-age-range/erConversion';
import fxConversion1218 from './12-18-age-range/fxConversion';
import icConversion1218 from './12-18-age-range/icConversion';
import itConversion1218 from './12-18-age-range/itConversion';
import ogConversion1218 from './12-18-age-range/ogConversion';
import plConversion1218 from './12-18-age-range/plConversion';
import smConversion1218 from './12-18-age-range/smConversion';
import wmConversion1218 from './12-18-age-range/wmConversion';

const scoreConversionTable511 = {
  at: atConversion,
  er: erConversion,
  fx: fxConversion,
  ic: icConversion,
  it: itConversion,
  og: ogConversion,
  pl: plConversion,
  sm: smConversion,
  wm: wmConversion,
};

const scoreConversionTable1218 = {
  at: atConversion1218,
  er: erConversion1218,
  fx: fxConversion1218,
  ic: icConversion1218,
  it: itConversion1218,
  og: ogConversion1218,
  pl: plConversion1218,
  sm: smConversion1218,
  wm: wmConversion1218,
};

const scoreConversionTables = {
  1: scoreConversionTable511,
  2: scoreConversionTable1218,
};

export default scoreConversionTables;
