import scoreConversionTables from './scoreConversionTables';

interface Sums {
  [key: string]: number;
}

const categoryLabels = {
  at: 'Attention (AT)',
  er: 'Emotional Regulation (ER)',
  fx: 'Flexibility (FX)',
  ic: 'Inhibitory Control (IC)',
  it: 'Initiation (IT)',
  og: 'Organization (OG)',
  pl: 'Planning (PL)',
  sm: 'Self Monitoring (SM)',
  wm: 'Working Memmory (WM)',
};

const getResults = (sums: Sums, ageGroup: number) => {
  const categories = Object.keys(sums);

  const results = categories.map((c) => {
    const sum = sums[c];
    const scoreConversionTable = scoreConversionTables[ageGroup];
    if (!scoreConversionTable[c]) return { error: 'could not score', c };

    const result = scoreConversionTable[c][sum];
    result.sum = sum;
    result.label = categoryLabels[c];
    return result;
  });
  return results;
};

export default getResults;
