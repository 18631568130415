import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import {
  Background,
  SearchGroup,
  CheckboxContainer,
  TableGroup,
  TableHeaderItem,
  Paginator,
  AddTutor,
  TutorsAdditionContainer,
  EditLearner,
} from './styles';
import { FieldsBox, Field, Checkbox } from '../../components/Form';
import { Modal } from '../../components';
import TutorsAdditionForm from './TutorsAddition';

import IState from '../../reducers';

import { getTutors } from './tutors.action';
import { resetTutorAdded } from './TutorsAddition/tutorsAdditionForm.action';

interface ISearchValues {
  filter: {
    searchInput: string;
    radioFilter: string;
  };
  sort: {
    order: string;
    accessor: string;
    sortableName?: string;
  };
  page: number;
}

interface IPaginatorValues {
  offset: number;
  data?: [];
  perPage?: number;
  currentPage: number;
}

export default function Tutors() {
  const initialSearchValues = {
    filter: {
      searchInput: '',
      radioFilter: 'firstName',
    },
    sort: {
      order: '',
      accessor: '',
      sortableName: '',
    },
    page: 0,
  };

  const initialEditValues = {
    id: '',
    aboutMe: '',
    calendarUrl: '',
    email: '',
    firstName: '',
    introVideoUrl: '',
    lastName: '',
    profileImageUrl: '',
    specialist: false,
    state: 0,
    titleDegree: '',
    online: false,
    inhome: false,
  };

  const dispatch = useDispatch();
  const [searchValues, setSearch] =
    useState<ISearchValues>(initialSearchValues);

  const [paginator, setPaginator] = useState<IPaginatorValues>({
    offset: 0,
    data: [],
    perPage: 5,
    currentPage: 0,
  });

  const { isMobile } = useSelector((state: IState) => state.devices);
  const [showTutorsModal, setShowTutorsModal] = useState(false);
  const [editData, setEditData] = useState(initialEditValues);

  useEffect(() => {
    dispatch(getTutors());
  }, [dispatch]);

  useEffect(() => {
    if (searchValues.filter.searchInput.length > 2) {
      dispatch(getTutors(searchValues));
    }
  }, [searchValues, dispatch]);

  const { tutorsData, page } = useSelector((state: IState) => state.tutors);

  const handleClear = () => {
    dispatch(getTutors());
    setSearch(initialSearchValues);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
        sortableName: 'firstName',
        sortable: true,
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        sortableName: 'lastName',
        sortable: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Calendar url',
        accessor: 'calendarUrl',
      },
      {
        Header: 'Online',
        accessor: 'online',
      },
      {
        Header: 'Inhome',
        accessor: 'inhome',
      },

      {
        Header: 'Intro Video Url',
        accessor: 'introVideoUrl',
      },
      {
        Header: 'About Me',
        accessor: 'aboutMe',
      },
      {
        Header: 'Profile Image Url',
        accessor: 'profileImageUrl',
      },
      {
        Header: 'Title Degree',
        accessor: 'titleDegree',
      },
    ],
    []
  );

  const handleCloseModal = () => {
    setShowTutorsModal(false);
    dispatch(resetTutorAdded());
  };

  const handleSelectPage = (number) => {
    const newSearch = {
      ...searchValues,
      page: number,
    };
    dispatch(getTutors(newSearch));
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = paginator.perPage ? selectedPage * paginator.perPage : 1;

    setPaginator({
      currentPage: selectedPage,
      offset,
    });
    handleSelectPage(selectedPage);
  };

  const renderPaginator = () =>
    page.totalPages > 1 ? (
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        breakClassName="break-me"
        pageCount={page.totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
      />
    ) : null;

  const handleChange = (event) => {
    event.target.type === 'text' && event.target.value === '' && handleClear();

    setSearch((prevState: ISearchValues) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
    }));
  };

  const handleSort = (column) => {
    const newOrder = {
      ...searchValues,
      sort: {
        order:
          searchValues.sort.order === '' || searchValues.sort.order === 'desc'
            ? 'asc'
            : 'desc',
        accessor: column.sortableName,
      },
    };
    if (column.sortable) {
      setSearch(newOrder);
      dispatch(getTutors(newOrder));
    }
  };

  const handleEditLearner = (row) => {
    setEditData(row);
    setShowTutorsModal(true);
  };

  const parseTableData = (item, accessor, row) => {
    if (typeof item === 'boolean') {
      return item ? 'Yes' : 'No';
    }
    if (accessor === 'firstName') {
      return (
        <EditLearner>
          <a href="#" onClick={() => handleEditLearner(row)}>
            {row.firstName}
          </a>
        </EditLearner>
      );
    }
    return item;
  };

  return (
    <Background>
      <FieldsBox>
        <SearchGroup>
          <Field
            name="searchInput"
            type="text"
            handleChange={handleChange}
            value={searchValues.filter.searchInput}
            placeholder="Search"
          />
          <CheckboxContainer margin>
            <Checkbox
              type="radio"
              name="radioFilter"
              value="firstName"
              checked={searchValues.filter.radioFilter === 'firstName'}
              handleChange={handleChange}
              label="First Name"
            />
            <Checkbox
              type="radio"
              name="radioFilter"
              value="lastName"
              checked={searchValues.filter.radioFilter === 'lastName'}
              handleChange={handleChange}
              label="Last Name"
            />
            <a href="#" onClick={handleClear}>
              clear
            </a>
          </CheckboxContainer>
        </SearchGroup>
        <AddTutor
          onClick={() => {
            setEditData(initialEditValues);
            setShowTutorsModal(true);
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} size="lg" />
          <span>
            <b>Add new tutor</b>
          </span>
        </AddTutor>
      </FieldsBox>
      <Modal
        isOpen={showTutorsModal}
        closeModal={handleCloseModal}
        full={isMobile}
        mw={1000}
      >
        <TutorsAdditionContainer>
          <TutorsAdditionForm editMode={editData} />
        </TutorsAdditionContainer>
      </Modal>
      {tutorsData.length ? (
        <TableGroup>
          <table
            style={{
              marginTop: 30,
              width: '90%',
              borderSpacing: 0,
            }}
          >
            <thead>
              <tr>
                {columns.map((column) => (
                  <th
                    style={{
                      fontWeight: 'bold',
                      cursor: `${column.sortable && 'pointer'}`,
                      width: column.accessor === 'aboutMe' ? 900 : 300,
                      minWidth: column.accessor === 'aboutMe' ? 250 : 70,
                    }}
                    onClick={() => handleSort(column)}
                    key={column.Header}
                  >
                    <TableHeaderItem>
                      {column.Header}
                      {searchValues.sort.accessor === column.sortableName &&
                        (searchValues.sort.order === 'desc' ? (
                          <FontAwesomeIcon icon={faAngleDown} />
                        ) : (
                          <FontAwesomeIcon icon={faAngleUp} />
                        ))}
                    </TableHeaderItem>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tutorsData.map((row, v) => (
                <tr key={row.id}>
                  {columns.map((column, i) => (
                    <td
                      style={{
                        padding: '10px',
                        background: v % 2 === 0 ? '#F6F6F6' : 'white',
                      }}
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                    >
                      {parseTableData(
                        row[column.accessor],
                        column.accessor,
                        row
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </TableGroup>
      ) : (
        <p>No results</p>
      )}
      <Paginator>{renderPaginator()}</Paginator>
    </Background>
  );
}
