import getScores from './getScores';
import getLowerUpperScores from './getLowerUpperScores';
import getLabel from './getLabel';

const getSections = (responses) => {
  const sectionRow = responses[0].filter(
    // Sections titles have spaces before them and are not empty
    // Special case for section after "upload" instructions
    (c, idx) =>
      !c ||
      !responses[0][idx - 1] ||
      responses[0][idx - 1]?.toLowerCase() === `upload image of kid's writing`
  );

  const questionRow = responses[1];
  const answerRow = responses[2];

  // Remove column for upload message
  const uploadIndex = sectionRow.findIndex(
    (c) => c.toLowerCase() === `upload image of kid's writing`
  );

  sectionRow.splice(uploadIndex, 1);
  questionRow.splice(uploadIndex, 1);
  answerRow.splice(uploadIndex, 1);

  let sections = sectionRow
    .map(
      (cell, idx) =>
        !!cell &&
        cell.length > 4 && {
          label: getLabel(cell),
          startIndex: idx,
        }
    )
    .filter(Boolean);

  sections = sections.map((section, idx) => {
    const updatedSection = { ...section };
    const nextSection = sections[idx + 1];
    if (nextSection) updatedSection.endIndex = nextSection.startIndex - 1;
    else updatedSection.endIndex = sectionRow.length;

    // First three sections have unique setup with lower and upper case lettered subsections
    if (idx <= 2) {
      const [lowerScores, upperScores] = getLowerUpperScores(
        updatedSection,
        questionRow,
        answerRow
      );
      updatedSection.lower = lowerScores;
      updatedSection.upper = upperScores;
    } else {
      // Other sections have individual questions with correct/incorrect
      updatedSection.scores = getScores(updatedSection, questionRow, answerRow);
    }

    return updatedSection;
  });

  return sections;
};

export default getSections;
