import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import styled from 'styled-components';

import ProtectedRoute from './config/ProtectedRoute';
import useDevices from './utils/useDevices';

import Home from './pages/Home';
import SignIn from './pages/SignIn';
import Navbar from './components/Navbar';
import Tutors from './pages/Tutors';
import Learner from './pages/Learner';
import Reports from './pages/Reports';

const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  min-height: calc(100vh - 80px);

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    min-height: 100vh;
  }
`;

export default function App(): JSX.Element {
  useDevices();
  return (
    <Router>
      <Route
        render={({ location }: RouteProps) =>
          [
            '/home',
            '/dashboard',
            '/myAccount',
            '/tutors',
            '/learner',
            '/reports',
          ].includes(location?.pathname as string) ? (
            <Navbar />
          ) : null
        }
      />
      <Main>
        <Switch>
          <Route path="/signIn" component={SignIn} />
          <ProtectedRoute path="/home" component={Home} />
          <ProtectedRoute path="/tutors" component={Tutors} />
          <ProtectedRoute path="/learner" component={Learner} />
          <ProtectedRoute path="/reports" component={Reports} />
          <Redirect from="*" to="/signIn" />
        </Switch>
      </Main>
    </Router>
  );
}
