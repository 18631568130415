import {
  SEND_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
} from '../pages/SignIn/signIn.action';

import { LOGOUT } from '../commonActions';

const initialState = {
  logged: false,
  loginFailure: '',
  loading: false,
};

export default function learnersAdditionData(state = initialState, action) {
  switch (action.type) {
    case SEND_LOGIN:
      return {
        ...state,
        loading: true,
        loginFailure: '',
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        logged: true,
        loading: false,
      };
    case LOGOUT:
      return {
        ...state,
        logged: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loginFailure: action.err,
        loading: false,
      };
    default:
      return state;
  }
}
